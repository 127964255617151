import React from 'react';
import PropTypes from 'prop-types';
import POIDetails from "../../../components/POI/POIDetails/POIDetails";

function POIPage({active, history, location}){

  return (
    <div
      className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${!active ? 'page-hidden' : 'page-active'}`}>
      <div className="POIPage h-100 w-100 position-absolute">
        <POIDetails history={history} location={location} fixedHeader={true}/>
      </div>
    </div>
  );
}

POIPage.propTypes = {
  loading: PropTypes.bool,
  active: PropTypes.bool,
  selection: PropTypes.object
};

POIPage.defaultProps = {
  loading: true
};

export default POIPage;
