import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import './AppInfoPage.scss';

function AppInfoPage (props) {
  const { title, image, description } = props;
  return (
    <div className={`AppInfoPage fade ${props.shown ? 'show' : 'd-none'}`}>
      <h5 className="text-center">{title}</h5>
      <div className="p-3">{image && <img src={image} alt="Logo"/>}</div>
      <div className="text-normal mb-4">{ReactHtmlParser(description)}</div>
    </div>
  );
}

AppInfoPage.propTypes = {
  shown: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};
AppInfoPage.defaultProps = {
  shown: false
};

export default AppInfoPage;
