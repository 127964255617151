function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { APP_GENERATE_SHORT_URL_DONE, APP_GENERATE_SHORT_URL_START, APP_GENERATE_SHORT_URL_FAIL } from '../../actionTypes';
var initialState = {
  url: null,
  error: null,
  loading: false
};
export var urlShortenerReducer = function urlShortenerReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case APP_GENERATE_SHORT_URL_START:
      return _extends({}, state, {
        error: null,
        loading: true
      });
    case APP_GENERATE_SHORT_URL_DONE:
      return _extends({}, state, {
        url: action.payload.url,
        error: null,
        loading: false
      });
    case APP_GENERATE_SHORT_URL_FAIL:
      return _extends({}, state, {
        url: null,
        error: action.payload.error,
        loading: false
      });
    default:
      return state;
  }
};