import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function ResultInfo(props) {
  var count = props.count;
  return props.render({
    count: count
  });
}
ResultInfo.propTypes = {
  count: PropTypes.number
};
export default withRender(ResultInfo);