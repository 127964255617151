import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function Items(props) {
  var loading = props.loading,
    error = props.error,
    items = props.items,
    selectedItem = props.selectedItem,
    info = props.info,
    setPage = props.setPage,
    setOrderBy = props.setOrderBy,
    selectItem = props.selectItem,
    hoverItem = props.hoverItem,
    hoveredItem = props.hoveredItem;
  return props.render({
    loading: loading,
    error: error,
    items: items,
    info: info,
    selectedItem: selectedItem,
    hoveredItem: hoveredItem
  }, {
    setPage: setPage,
    setOrderBy: setOrderBy,
    selectItem: selectItem,
    hoverItem: hoverItem
  });
}
Items.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  items: PropTypes.array,
  selectedItem: PropTypes.object,
  info: PropTypes.object
};
export default withRender(Items);