import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import logo from '../../../assets/img/vectuel_logo.png';
import './FullPageLoading.scss';
import {useTranslation} from "react-i18next";

function FullPageLoading() {
    const {t} = useTranslation();
    return (
        <div className="MapLoader bg-gray w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="col-10 col-md-4">
                <ProgressBar variant="gradient progress-bar-indeterminate" now={60}/>
                <p className="text-uppercase brand text-center text-black mt-5">{t('loadingPage.poweredBy')}</p>
                <img className="logo text-center d-block m-auto" src={logo} alt="vectuel"/>
            </div>
        </div>
    );
}

FullPageLoading.propTypes = {};
FullPageLoading.defaultProps = {};

export default FullPageLoading;
