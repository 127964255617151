import React, { useEffect } from 'react';
import withRender from '../../hoc/withRender';
import PropTypes from 'prop-types';
var Layers = function Layers(props) {
  var layers = props.layers,
    activeLayers = props.activeLayers,
    toggleLayer = props.toggleLayer;
  return props.render({
    layers: layers,
    activeLayers: activeLayers
  }, {
    toggleLayer: toggleLayer
  });
};
Layers.propTypes = {
  children: PropTypes.func.isRequired,
  toggleLayer: PropTypes.func,
  layers: PropTypes.array
};
export default withRender(Layers);