import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function ResultOrder(props) {
  var order = props.order,
    orderOptions = props.orderOptions,
    onChangeOrder = props.onChangeOrder,
    showListOrder = props.showListOrder;
  var changeOrder = function changeOrder(newOrder) {
    onChangeOrder(newOrder);
  };
  return props.render({
    order: order,
    orderOptions: orderOptions,
    showListOrder: showListOrder
  }, {
    changeOrder: changeOrder
  });
}
ResultOrder.propTypes = {
  order: PropTypes.object,
  orderOptions: PropTypes.array,
  onChangeOrder: PropTypes.func,
  showListOrder: PropTypes.bool
};
export default withRender(ResultOrder);