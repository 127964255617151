import React, {useState} from "react";
import {Button, Col, Container, Dropdown, Row} from "react-bootstrap";
import {TargomoIsochrone} from '@mappr/react-lib';
import {useTranslation} from "react-i18next";
import './PoiIsochroneContent.scss'
import classNames from 'classnames';

export const PoiIsochroneContent = (props) => {
    const {t} = useTranslation();
    const [timeValue, setTimeValue] = useState(5)
    const [activeTravelType, setActiveTravelType] = useState(undefined)
    const handleTimeChange = (eventKey) => {
        setTimeValue(eventKey)
        if (activeTravelType) {
            setActiveTravelType('walk')
        }
    }
    const handleChangeTravelType = (eventKey) => {
        setActiveTravelType(eventKey)
    }

    return (
        <TargomoIsochrone>
            {({}, {handleIsochrone}) =>
                <Container>
                    <Row>
                        <Col lg={12} md={12} className={'mb-4'}>
                            <h6>{t("isochrone.title")}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} md={12}>
                            <Row className={'mb-3'}>
                                <Col>
                                    <span className={'mr-2 font-weight-bold'}>{t("isochrone.accessible")} :</span>
                                    <Dropdown onSelect={handleTimeChange} className={'d-inline-block'}>
                                        <Dropdown.Toggle className={'bg-transparent text-black'}>
                                            {timeValue} {t("isochrone.min")}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {[5, 10, 15, 30].map((_) => <Dropdown.Item key={_}
                                                                                       eventKey={_}>{_}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row className={'mb-3'}>
                                <Col>
                                    <span className={'mr-2 font-weight-bold'}>{t("isochrone.mode")} :</span>
                                    <div onClick={() => handleChangeTravelType('walk')}
                                         className={classNames({'active border-primary': activeTravelType === 'walk'}, 'marker-container d-inline-flex align-middle mr-2')}
                                         title="Walk">
                                        <i className="icon ic-material_ic_directions_walk_48px  text-bigger cursor-pointer p-1"></i>
                                    </div>
                                    <div onClick={() => handleChangeTravelType('bike')}
                                         className={classNames({'active border-primary': activeTravelType === 'bike'}, 'marker-container d-inline-flex align-middle mr-2')}
                                         title="Bike">
                                        <i className="icon ic-material_ic_directions_bike_48px  text-bigger  cursor-pointer p-1"></i>
                                    </div>
                                    <div onClick={() => handleChangeTravelType('car')}
                                         className={classNames({'active border-primary': activeTravelType === 'car'}, 'marker-container d-inline-flex align-middle mr-2')}
                                         title="Public transportation">
                                        <i className="icon ic-material_ic_directions_car_48px  text-bigger cursor-pointer p-1"></i>
                                    </div>
                                    <div onClick={() => handleChangeTravelType('transit')}
                                         className={classNames({'active border-primary': activeTravelType === 'transit'}, 'marker-container d-inline-flex align-middle mr-2')}
                                         title="Car">
                                        <i className="icon ic-material_ic_directions_bus_48px  text-bigger cursor-pointer p-1"></i>
                                    </div>

                                </Col>
                            </Row>

                        </Col>
                        <Col lg={4} md={12}>
                            <Button className={`mb-3`} disabled={!activeTravelType} onClick={() => handleIsochrone({
                                timeValue,
                                activeTravelType
                            })}>{t("isochrone.seeOnTheMap")}</Button>
                        </Col>
                    </Row>
                </Container>
            }
        </TargomoIsochrone>
    )
}
