import React from "react";
import {
  Button,
  Dropdown,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context";
import vectuelLogo from "../../../assets/img/vectuel_logo.png";
import vectuelLogoWhite from "../../../assets/img/vectuel_logo_white.png";
import "./Header.scss";
import SubHeader from "../SubHeader/SubHeader";
import { URLManager } from "@mappr/react-lib";

const Header = ({
  projectConfigs,
  isFirstPage,
  handleOpenTutorial,
  handleSubheaderToggle,
}) => {
  const {
    headlineLink,
    headline,
    domain,
    appLogos,
    tutorialEnabled,
    locales,
    appConfigModalEnabled,
    navbarBrandLink,
  } = projectConfigs;
  const { t } = useTranslation();

  return (
    <AppContext.Consumer>
      {({ theme, configModal, layout, locale, shareModal, mprApiUrl, authenticated, logout }) => (
        <div className="fixed-top d-none d-sm-none d-md-flex flex-column">
          {projectConfigs.showHeader && (
            <Navbar
              expand
              variant={theme}
              className={`DesktopHeader ${
                !(
                  layout !== "headerLeft" &&
                  layout !== "headerRight" &&
                  layout !== "horizontal"
                ) && "shadow-none"
              }`}
            >
              {appLogos &&
                appLogos.map((logo, index) => (
                  <Navbar.Brand
                    href={logo.url}
                    target="_blank"
                    className="py-0 mx-0"
                    key={`logo-${index}`}
                  >
                    <img
                      src={`${mprApiUrl}/projects/${domain}/logo/${logo.image.fileName}`}
                      height="32"
                      alt=""
                    />
                    <div className="pipe" />
                  </Navbar.Brand>
                ))}
              <div className="navbar-brand py-0">
                <Button
                  variant="link"
                  href={headlineLink}
                  className="font-weight-bold text-smaller py-1"
                >
                  {headline[locale.value]}
                </Button>
              </div>
              <Nav className="mr-auto" />
              {isFirstPage && tutorialEnabled && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 160 }}
                  overlay={
                    <Tooltip id={`tooltip-help`}>{t("header.help")}</Tooltip>
                  }
                >
                  <Button
                    onClick={() => handleOpenTutorial(true)}
                    variant="link"
                    className="pt-0 pb-0 help"
                  >
                    <i className="icon ic-material_ic_help_outline_24px" />
                  </Button>
                </OverlayTrigger>
              )}
              <URLManager>
                {({ shortUrl }, { generateShortUrl }) => {
                  return (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 160 }}
                        overlay={
                          <Tooltip id={`tooltip-share`}>
                            {t("header.share")}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={() => {
                            generateShortUrl();
                            shareModal.handleOpen();
                          }}
                          variant="link"
                          className="mx-3"
                        >
                          <i className="icon icon-arrow" />
                        </Button>
                      </OverlayTrigger>
                    </>
                  );
                }}
              </URLManager>
              {appConfigModalEnabled && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 160 }}
                  overlay={
                    <Tooltip id={`tooltip-settings`}>
                      {t("header.settings")}
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={configModal.handleOpen}
                    variant="link"
                    className="mx-3"
                  >
                    <i className="icon icon-settings_2" />
                  </Button>
                </OverlayTrigger>
              )}
              {authenticated && projectConfigs.authEnabled && projectConfigs.authType === 'email' && (
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 160 }}
                  overlay={
                    <Tooltip id={`tooltip-logout`}>
                      {t("header.logout")}
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={logout}
                    variant="link"
                  >
                    <i className="icon ic-material_ic_exit_to_app_48px" />
                  </Button>
                </OverlayTrigger>
              )}
              {locales?.length > 1 && (
                <Dropdown
                  id="menu-languages"
                  role="group"
                  aria-label="Language"
                  className="dropdown"
                >
                  <AppContext.Consumer>
                    {({ locale }) => (
                      <React.Fragment>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 160 }}
                          overlay={
                            <Tooltip id={`tooltip-lang-toggle`}>
                              {t("header.language")}
                            </Tooltip>
                          }
                        >
                          <Dropdown.Toggle
                            variant="outline-primary"
                            className="font-weight-normal text-smaller py-1"
                          >
                            {locale.value && locale.value.toUpperCase()}
                          </Dropdown.Toggle>
                        </OverlayTrigger>

                        <Dropdown.Menu
                          alignRight={true}
                          className="dropdown-menu-covered"
                        >
                          {locales.map((aLocale) => (
                            <Dropdown.Item
                              key={aLocale.value}
                              variant="button"
                              className={
                                "font-weight-normal " +
                                (theme === "light" &&
                                aLocale.value === locale.value
                                  ? "text-primary"
                                  : aLocale.value === locale.value
                                  ? "text-body"
                                  : "text-dark")
                              }
                              onClick={() => locale.setValue(aLocale.value)}
                            >
                              {aLocale.value.toUpperCase()}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </React.Fragment>
                    )}
                  </AppContext.Consumer>
                </Dropdown>
              )}
              <div className="ml-4">
                <Navbar.Brand
                  href={navbarBrandLink || "https://vectuel.com/"}
                  target="_blank"
                  className="mr-0 py-0"
                >
                  <span className="mr-2 font-weight-bold text-smaller text-primary">{t("header.poweredBy")}</span>
                  <img
                    src={theme === "light" ? vectuelLogo : vectuelLogoWhite}
                    height="32"
                    alt=""
                  />
                </Navbar.Brand>
              </div>
            </Navbar>
          )}
          {layout === "headerLeft" ||
          layout === "headerRight" ||
          layout === "horizontal" ? (
            <SubHeader 
              onStateToggle={handleSubheaderToggle} 
              showMapViewFilter={!!projectConfigs.mapViewFilter && projectConfigs.mapViewFilter.length > 0}
              />
          ) : null}
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default Header;
