import { combineReducers } from '@reduxjs/toolkit';
import itemsReducer from './itemsSlice';
import selectedItemReducer from './selectedItemSlice';
import hoveredItemReducer from './hoveredItemSlice';
import filtersReducer from './filtersSlice';
import searchReducer from './searchSlice';
import navitiaSearchReducer from './navitiaSearchSlice';
export default combineReducers({
  data: itemsReducer,
  filters: filtersReducer,
  selected: selectedItemReducer,
  hovered: hoveredItemReducer,
  search: searchReducer,
  navitiaSearch: navitiaSearchReducer
});