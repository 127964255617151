import React from "react";
import PropTypes from "prop-types";
import { Layers } from "@mappr/react-lib";
import { Dropdown } from "react-bootstrap";
import "./LayersMenu.scss";
import { useTranslation } from "react-i18next";
import { getDomain } from "../../../utils/envUtils";
import { AppContext } from "../../../context";
import { useContext } from "react";

function LayersMenu(props) {
  const { t } = useTranslation();
  const { mprApiUrl } = useContext(AppContext);

  return (
    <Layers group={"secondary"}>
      {({ layers, activeLayers }, { toggleLayer }) => (
        <Layers group={"basemap"}>
          {(
            { layers: basemaps, activeLayers: activeBasemap },
            { toggleLayer: toggleBasemap }
          ) => {
            let toggleableLayersCount = 0;
            layers.forEach((section) => {
              section.secondaryLayers.forEach((_) => {
                if (_.canBeToggled) {
                  toggleableLayersCount++;
                }
              });
            });

            if (!toggleableLayersCount && basemaps.length < 2) {
              return null;
            }

            return (
              <Dropdown className="LayersMenu dropup d-inline-block">
                <Dropdown.Toggle
                  id="layers-menu-dropdown"
                  bsPrefix="dd-toggle"
                  variant="primary"
                  className={`btn-${props.buttonType} p-0 line-height-1-5 text-bigger`}
                >
                  <i className="icon-layers" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-left">
                  {layers.map((section) => {
                    let sectionHeader = null;
                    if (section.id) {
                      sectionHeader = (
                        <Dropdown.Header
                          key={`section_${section.id}`}
                          as="p"
                          className="text-smaller font-weight-bold"
                        >
                          {t(
                            `project:map.layers.sections.section_${section.id}`
                          )}
                        </Dropdown.Header>
                      );
                    }

                    let sectionLayers = section.secondaryLayers.map(
                      (aLayer) => {
                        const inputId = `layer-${aLayer.name}-check`;
                        return aLayer.canBeToggled ? (
                          <Dropdown.Item
                            key={aLayer.name}
                            onClick={(ev) =>
                              toggleLayer(aLayer.name, "secondary")
                            }
                          >
                            <div className="custom-control custom-checkbox mr-2">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={inputId}
                                checked={activeLayers.includes(aLayer.name)}
                                readOnly
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={inputId}
                              >
                                {t(`project:map.layers.${aLayer.name}`)}
                              </label>
                            </div>
                          </Dropdown.Item>
                        ) : null;
                      }
                    );

                    return [sectionHeader, ...sectionLayers];
                  })}
                  <Dropdown.Header
                    as="p"
                    className="text-smaller font-weight-bold"
                  >
                    {t("map.layers.sections.basemaps")}
                  </Dropdown.Header>

                  <Dropdown.Item as="div" className="py-0">
                    <div className="base-maps-container horizontal-scroll pb-0">
                      {basemaps.map((aBgLayer, index) => {
                        return (
                          <img
                            key={aBgLayer.id}
                            src={`${mprApiUrl}/projects/common${aBgLayer.icon.filePath}`}
                            alt={aBgLayer.id}
                            onClick={() => {
                              if (activeBasemap !== aBgLayer.name) {
                                sessionStorage.setItem(
                                  `${getDomain()}-mpr-base-map`,
                                  aBgLayer.name
                                );
                                toggleBasemap(aBgLayer.name, "basemap");
                              }
                            }}
                            className={`rounded border ${
                              activeBasemap === aBgLayer.name
                                ? "border-primary"
                                : ""
                            } ${index < basemaps.length - 1 ? "mr-2" : ""}`}
                          />
                        );
                      })}
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          }}
        </Layers>
      )}
    </Layers>
  );
}

LayersMenu.propTypes = {
  buttonType: PropTypes.oneOf(["round", "square"]).isRequired,
};
LayersMenu.defaultProps = {
  buttonType: "round",
};

export default LayersMenu;
