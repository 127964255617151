function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import React from 'react';
import withRender from '../hoc/withRender';
function Mappr(props) {
  var loading = props.loading,
    project = props.project,
    error = props.error,
    authError = props.authError,
    theUser = props.theUser,
    onLogin = props.onLogin,
    onLogout = props.onLogout,
    onSetLanguage = props.onSetLanguage,
    basemaps = props.basemaps,
    secondaryLayers = props.secondaryLayers,
    language = props.language,
    draw = props.draw;
  var renderParams = {
    loading: loading,
    basemaps: basemaps,
    secondaryLayers: secondaryLayers,
    project: project,
    authenticated: true,
    error: error,
    authError: authError,
    language: language,
    draw: draw
  };
  var renderMethods = {
    login: function login(params) {
      return onLogin(params);
    },
    logout: function logout(params) {
      return onLogout(params);
    },
    setLanguage: function setLanguage(params) {
      return onSetLanguage(params);
    }
  };
  if (!project || !project.configs.authEnabled) {
    return props.render(renderParams, renderMethods);
  }
  return props.render(_extends({}, renderParams, {
    authenticated: project.configs.authEnabled && !!theUser
  }), renderMethods);
}
Mappr.propTypes = {};
export default withRender(Mappr);