import React from "react";
import { AppContext } from "../../../context";
import { Dropdown, Nav, Navbar, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import DropdownItem from "react-bootstrap/DropdownItem";
import vectuelLogo from "../../../assets/img/vectuel_logo.png";
import vectuelLogoWhite from "../../../assets/img/vectuel_logo_white.png";
import { useTranslation } from "react-i18next";
import "./Header.scss";

function Header({ projectConfigs }) {
  const { appLogos, navbarBrandLink } = projectConfigs;
  const { t } = useTranslation();
  return (
    <AppContext.Consumer>
      {({ locale, theme, mprApiUrl, authenticated, logout }) => (
        <Navbar
          expand
          variant={theme}
          fixed="top"
          className="MobileHeader px-3 border-0 shadow-sm"
        >
          {appLogos && appLogos.length ? (
            <Navbar.Brand
              href={appLogos[0].url}
              target="_blank"
              className="py-0"
            >
              <img
                src={`${mprApiUrl}/projects/${projectConfigs.domain}/logo/${appLogos[0].image.fileName}`}
                height="32"
                alt=""
              />
            </Navbar.Brand>
          ) : null}

          <Nav className="mr-auto" />
          <NavLink
            to={{
              pathname: `/${locale.value}/map`,
              hash: window.location.hash,
            }}
            exact={true}
            className={`text-decoration-none px-3 py-1 ${
              theme === "light" ? "btn-light bg-white" : "text-body"
            } mx-1`}
            activeClassName="active"
          >
            <i className="icon icon-location_pin" />
          </NavLink>
          <NavLink
            to={{
              pathname: `/${locale.value}/list`,
              hash: window.location.hash,
            }}
            className={`text-decoration-none px-3 py-1 ${
              theme === "light" ? "btn-light bg-white" : "text-body"
            } mx-1`}
            activeClassName="active"
          >
            <i className="icon icon-list" />
          </NavLink>
          <NavLink
            to={{
              pathname: `/${locale.value}/search`,
              hash: window.location.hash,
            }}
            className={`text-decoration-none px-3 py-1 position-relative ${
              theme === "light" ? "btn-light bg-white" : "text-body"
            } mx-1`}
            activeClassName="active"
          >
            <i className="icon icon-mobile_filter" />
            {/*<QueryInfoContainer/>*/}
          </NavLink>
          <Dropdown className="py-0 text-normal">
            <Dropdown.Toggle
              variant="link"
              className="pl-3 py-1 no-chevron text-body"
            >
              <i className="icon icon-more" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight={true} className="mt-3 py-0 pb-2">
              {projectConfigs.locales?.length > 1 && (
                <div className="px-3 py-2">
                  {projectConfigs.locales.map((aLocale) => (
                    <Button
                      key={aLocale.value}
                      variant="link"
                      className={
                        "p-0 font-weight-bold text-smaller mr-4 " +
                        (aLocale.value === locale.value
                          ? "text-primary"
                          : "text-body")
                      }
                      onClick={() => locale.setValue(aLocale.value)}
                    >
                      {aLocale.value.toUpperCase()}
                    </Button>
                  ))}
                </div>
              )}
              {authenticated &&
                projectConfigs.authEnabled &&
                projectConfigs.authType === "email" && (
                  <DropdownItem
                    onClick={logout}
                    target="_blank"
                    className="px-3 py-2"
                  >
                    {t("header.logout")}
                  </DropdownItem>
                )}
              <DropdownItem
                href={navbarBrandLink || "https://vectuel.com/"}
                target="_blank"
                className="px-3 py-2"
              >
                <small className="mr-2">{t("header.poweredBy")}</small>
                <img
                  src={theme === "light" ? vectuelLogo : vectuelLogoWhite}
                  height="32"
                  alt=""
                />
              </DropdownItem>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      )}
    </AppContext.Consumer>
  );
}

export default Header;
