import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import mapprLogo from '../../../assets/img/vectuel_logo_t.png';
import './MapLoader.scss';

function MapLoader (props) {
  return (
    <div className="MapLoader bg-gray w-100 h-100 d-flex justify-content-center align-items-center">
      <div className="col-10 col-md-4">
        <ProgressBar variant="gradient progress-bar-indeterminate" now={60}/>
        <p className="text-uppercase brand text-center text-black mt-5">Powered by</p>
        <img className="logo text-center d-block m-auto" src={mapprLogo} alt="Mappr"/>
      </div>
    </div>
  );
}

MapLoader.propTypes = {};
MapLoader.defaultProps = {};

export default MapLoader;
