import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function MapDraw(props) {
  var showPolygonSwitch = props.showPolygonSwitch,
    draw = props.draw;
  return props.render({
    showPolygonSwitch: showPolygonSwitch,
    draw: draw
  });
}
MapDraw.propTypes = {
  showPolygonSwitch: PropTypes.bool,
  draw: PropTypes.object
};
export default withRender(MapDraw);