import React, { useEffect } from "react";
import Layouts from "../../layouts/Layouts";
import MobileLayout from "../../layouts/MobileLayout/MobileLayout";
import DesktopLayout from "../../layouts/DesktopLayout/DesktopLayout";
import i18n from "../../i18n";
import { useAnalytics } from "@mappr/react-lib";
import { getDomain } from "../../utils/envUtils";
import { getHash, setHash } from "../../utils/urlUtils";
import FullPageLoading from "../loaders/FullPageLoading/FullPageLoading";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "../../assets/img/vectuel_logo.png";
import Login from "../Login/Login";

const MainContainer = ({
  project,
  domain,
  loading,
  basemaps,
  appState,
  setAppState,
  authenticated,
  logout,
  login,
  error,
  authError,
  draw
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (project) {
      const d = getDomain();
      const currentTheme = sessionStorage.getItem(`${d}-mpr-theme`);
      const activeLayout = sessionStorage.getItem(`${d}-mpr-layout`);
      const activeBaseMap = sessionStorage.getItem(`${d}-mpr-base-map`);

      const currentBasemap = project.configs.basemaps.filter(
        (basemap) => basemap.default
      );

      const activeBaseMapAllowed = project.configs.basemaps.filter(
        (basemap) => basemap.id === activeBaseMap
      );

      const urlBasemap = getHash("basemap");
      const urlBasemapAllowed =
        urlBasemap &&
        project.configs.basemaps.filter((basemap) => basemap.id === urlBasemap);

      if (!loading) {
        const defaultResultListView = project.configs.defaultResultListView;
        const defaultTheme = project.configs.defaultTheme;
        const defaultLayout = project.configs.defaultLayout;
        const defaultBasemap = currentBasemap[0].id;

        if (!currentTheme) {
          sessionStorage.setItem(`${d}-mpr-theme`, defaultTheme);
        }

        setAppState({ prop: "theme", value: currentTheme || defaultTheme });

        if (
          !activeLayout ||
          !project.configs.appConfigModalEnabled ||
          !project.configs.appConfigModalLayoutsEnabled
        ) {
          sessionStorage.setItem(`${d}-mpr-layout`, defaultLayout);
          setAppState({ prop: "layout", value: defaultLayout });
        }

        if (urlBasemap && urlBasemapAllowed[0]) {
          sessionStorage.setItem(`${d}-mpr-base-map`, urlBasemap);
          setAppState({ prop: "basemap", value: urlBasemap });
        } else if (activeBaseMap && activeBaseMapAllowed[0]) {
          sessionStorage.setItem(`${d}-mpr-base-map`, activeBaseMap);
          setHash("basemap", activeBaseMap);
          setAppState({ prop: "basemap", value: activeBaseMap });
        } else {
          sessionStorage.setItem(`${d}-mpr-base-map`, defaultBasemap);
          setHash("basemap", defaultBasemap);
          setAppState({ prop: "basemap", value: defaultBasemap });
        }

        setAppState({ prop: "listViewType", value: defaultResultListView });
      }
    } else {
      setAppState({ prop: "theme", value: 'light' });
    }
  }, [project, loading, setAppState]);

  const { sendPageView } = useAnalytics();

  useEffect(() => {
    if (project) {
      document.documentElement.style.setProperty(
        "--primary",
        project.configs.primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary",
        project.configs.secondaryColor
      );
      sendPageView();
      setAppState({ prop: "projectConfigs", value: project.configs });
      setAppState({ prop: "authenticated", value: authenticated });
      setAppState({ prop: "logout", value: logout });
      if (project.translations) {
        Object.entries(project.translations).forEach(([lang, resources]) => {
          const pt = {};
          resources.forEach((_) => {
            pt[_.key] = _.value;
          });
          i18n.addResourceBundle(lang, "project", pt);
        });
      }
    }
  }, [project]);
  if (error) {
    let err = typeof error === "string" ? JSON.parse(error) : error;
    if (
      err.graphQLErrors &&
      err.graphQLErrors[0] &&
      err.graphQLErrors[0].message === "Not Authorised!"
    ) {
      return (
        <div className="MapLoader bg-gray w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-4">
            <Alert variant="light" dismissible={false} className="text-center">
              <h4 className="text-danger">403 Unauthorized.</h4>
              <p className="text-danger">
                {" "}
                The resource you are requesting is unavailable.
              </p>
            </Alert>
            <p className="text-uppercase brand text-center text-black mt-5">
              {t("loadingPage.poweredBy")}
            </p>
            <img
              className="logo text-center d-block m-auto"
              src={logo}
              alt="vectuel"
            />
          </div>
        </div>
      );
    } else if (
      (err.graphQLErrors &&
        err.graphQLErrors.length &&
        err.graphQLErrors[0].extensions?.exception?.key ===
          "DataSetProjectNotFound") ||
      err.code === 404
    ) {
      return (
        <div className="MapLoader bg-gray w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="col-10 col-md-4">
            <Alert variant="light" dismissible={false} className="text-center">
              <h4 className="text-danger">404 Not Found.</h4>
              <p className="text-danger">
                {" "}
                The resource you are requesting is not available.
              </p>
            </Alert>
            <p className="text-uppercase brand text-center text-black mt-5">
              {t("loadingPage.poweredBy")}
            </p>
            <img
              className="logo text-center d-block m-auto"
              src={logo}
              alt="vectuel"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="MapLoader bg-gray w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="col-10 col-md-4">
          <Alert variant="light" dismissible={false} className="text-center">
            <h4 className="text-danger">500 Something went wrong.</h4>
            <p className="text-danger"> {error.message}</p>
          </Alert>
          <p className="text-uppercase brand text-center text-black mt-5">
            {t("loadingPage.poweredBy")}
          </p>
          <img
            className="logo text-center d-block m-auto"
            src={logo}
            alt="vectuel"
          />
        </div>
      </div>
    );
  }

  if (loading || !appState.stylesLoaded) {
    return <FullPageLoading />;
  }

  if (!authenticated && project?.configs?.authType === 'email') {
    return <Login onLogin={login} error={authError} loading={loading} project={project}/>;
  }
  
  return (
    <Layouts>
      {(mode) =>
        mode === "mobile" ? (
          <MobileLayout
            domain={domain}
            loading={loading}
            project={project}
            basemaps={basemaps}
          />
        ) : (
          <DesktopLayout
            domain={domain}
            loading={loading || !appState.stylesLoaded}
            project={project}
            basemaps={basemaps}
            draw={draw}
          />
        )
      }
    </Layouts>
  );
};

export default MainContainer;
