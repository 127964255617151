function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { AUTH_LOGIN_START, AUTH_LOGIN_DONE, AUTH_LOGIN_FAIL, AUTH_LOGOUT_START, AUTH_LOGOUT_DONE, AUTH_LOGOUT_FAIL } from '../../actionTypes';
var initialState = {
  loading: false,
  theUser: null
};
export default function authReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case AUTH_LOGIN_START:
      {
        return _extends({}, state, {
          loading: true,
          theUser: null
        });
      }
    case AUTH_LOGIN_DONE:
      {
        var _action$payload = action.payload,
          user = _action$payload.user,
          token = _action$payload.token,
          projectId = _action$payload.projectId;
        // const now = new Date();
        // const expires = new Date(now.getTime() + 15 * 24 * 60 * 60000);
        var sessionObject = {
          // expiresAt: expires,
          user: {
            id: user.id
          },
          token: token
        };
        localStorage.setItem("mpr." + projectId + ".auth", JSON.stringify(sessionObject));
        return _extends({}, state, {
          loading: false,
          error: null,
          theUser: user
        });
      }
    case AUTH_LOGIN_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          error: action.payload.error
        });
      }
    case AUTH_LOGOUT_START:
      {
        return _extends({}, state, {
          loading: true
        });
      }
    case AUTH_LOGOUT_DONE:
      {
        localStorage.removeItem("mpr." + action.payload.projectId + ".auth");
        return _extends({}, state, {
          loading: false,
          theUser: null,
          error: null
        });
      }
    case AUTH_LOGOUT_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          error: action.payload.error
        });
      }
    default:
      return state;
  }
}