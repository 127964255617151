export var buildDatasetQuery = function buildDatasetQuery(selectedValues, masks, lang, filterLogic, filterKey, filters) {
  var _query;
  if (masks === void 0) {
    masks = [];
  }
  if (lang === void 0) {
    lang = 'fr';
  }
  var query = [];

  // if (!filters) {
  //   return query;
  // }

  Object.keys(selectedValues).forEach(function (key) {
    if (filterLogic !== 'or' || key !== filterKey) {
      if (selectedValues[key].length > 1) {
        var filterQuery = [];
        if (filters && filters[key]) {
          if (filterLogic === 'or') {
            filterQuery = ['match', key].concat(selectedValues[key]);
          } else {
            filterQuery.push(filterLogic);
            if (filters && filters[key] && filters[key].type === 'range' || filters[key].type === 'daterange') {
              filterQuery.push(['match', key].concat(selectedValues[key]));
            } else {
              selectedValues[key].map(function (value) {
                filterQuery.push(['match', key, value]);
              });
            }
          }
          query.push(filterQuery);
        } else {
          if (filters && filters[key] && filters[key].type !== 'range' && filters[key].type !== 'daterange') {
            filterQuery.push(filterLogic);
            selectedValues[key].map(function (value) {
              filterQuery.push(['match', key, value]);
            });
            query.push(filterQuery);
          }
        }
      } else {
        if (filters && filters[key] && (filters[key].type === 'range' || filters[key].type === 'daterange')) {
          query.push(['match', key].concat(selectedValues[key]));
        } else {
          query.push(['match', key, selectedValues[key][0]]);
        }
      }
    }
  });
  masks.map(function (mask) {
    return query.push(['match', "q__" + lang, mask]);
  });
  if (((_query = query) === null || _query === void 0 ? void 0 : _query.length) > 1) {
    query.unshift('and');
  } else {
    query = query.pop();
  }
  return query;
};
export var transformProperties = function transformProperties(properties, language, defaultLanguage, fields, includeNested) {
  if (includeNested === void 0) {
    includeNested = false;
  }
  var localizedProperties = {};
  if (!fields.length) {
    var uniqueFields = [];
    Object.keys(properties).forEach(function (d) {
      var tmp = d.split('::')[0];
      // remove duplication, delete mediasets
      if (tmp !== 'mediasets' && !uniqueFields.includes(tmp)) {
        uniqueFields.push(tmp);
      }
    });
    fields = uniqueFields;
  }
  fields.forEach(function (f) {
    if (properties[f + "::" + language]) {
      localizedProperties[f] = properties[f + "::" + language];
    } else if (properties[f + "::" + defaultLanguage]) {
      localizedProperties[f] = properties[f + "::" + defaultLanguage];
    } else {
      localizedProperties[f] = properties[f];
    }
    if (includeNested) {
      if (localizedProperties[f] && typeof localizedProperties[f] === 'object' && !Array.isArray(localizedProperties[f])) {
        var nestedFields = new Set();
        Object.keys(localizedProperties[f]).forEach(function (d) {
          var tmp = d.split('::')[0];
          // remove duplication, delete mediasets
          nestedFields.add(tmp);
        });
        localizedProperties[f] = transformProperties(localizedProperties[f], language, defaultLanguage, Array.from(nestedFields));
      } else if (Array.isArray(localizedProperties[f]) && localizedProperties[f].length) {
        var _nestedFields = new Set();
        if (typeof localizedProperties[f][0] === 'object') {
          Object.keys(localizedProperties[f][0]).forEach(function (d) {
            var tmp = d.split('::')[0];
            _nestedFields.add(tmp);
          });
          localizedProperties[f] = localizedProperties[f].map(function (p) {
            return transformProperties(p, language, defaultLanguage, Array.from(_nestedFields));
          });
        }
      }
    }
  });
  localizedProperties.mediasets = properties.mediasets;
  return localizedProperties;
};