import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/**
 * A container component that handles custom items addition on the map.
 * It will add whatever is supplied as its children into one of the provided corners of the map.
 */
var MapControl = function MapControl(props) {
  var mapMenuContainer = document.getElementsByClassName("map-menu-container-" + props.position)[0];
  if (!mapMenuContainer || !props.children) {
    return null;
  }
  return ReactDOM.createPortal(props.children, mapMenuContainer);
};
MapControl.propTypes = {
  position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right'])
};
MapControl.defaultProps = {
  position: 'bottom-left'
};
export default MapControl;