import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardImg, Image } from "react-bootstrap";
import { AppContext } from "../../../context";
import { useTranslation } from "react-i18next";

const iconNamesByType = {
  photo: "icon-photo",
  video: "icon-play",
  "3d": "icon-three_d",
  web: "icon-www",
  pdf: "icon-file_pdf",
};

function POIGalleryButton({ thumbnail, medias, mediaType }) {
  const { t, i18n } = useTranslation();
  const { mprApiUrl, projectConfigs } = useContext(AppContext);
  const [thumbnailImage, setThumbnailImage] = useState();
  const [icon, setIcon] = useState();

  useEffect(() => {
    const media = projectConfigs.medias?.find((_) => _.type === mediaType);

    if (media && media.image && media.image.image) {
      setThumbnailImage(
        `${mprApiUrl}/projects/${
          projectConfigs.domain
        }/image/512x512_cover/${media.image.image.filePath
          .split("/" + projectConfigs.domain + "/")
          .join("")}`
      );
    } else {
      setThumbnailImage(thumbnail.original);
    }

    if (media && media.icon && media.icon.image) {
      setIcon(
        `${mprApiUrl}/projects/${
          projectConfigs.domain
        }/image/20x20/${media.icon.image.filePath
          .split("/" + projectConfigs.domain + "/")
          .join("")}`
      );
    } else {
      setIcon(null);
    }
  }, [mediaType, projectConfigs, mprApiUrl, thumbnail]);

  return (
    <AppContext.Consumer>
      {({ galleryModal, streetViewModal }) => (
        <Card
          className="col-sm-12 col-md-6 border-0 p-3"
          onClick={() =>
            mediaType === "street_view"
              ? streetViewModal.setMedias(medias)
              : galleryModal.setMedias(medias, mediaType)
          }
        >
          <CardImg src={thumbnailImage} />
          <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
            <Button variant="primary">
              {
                icon ? (
                    <Image className="mr-2" src={icon} />
                ) : mediaType === "street_view" ? (
                  <div
                    className="mr-2 d-inline-block"
                    style={{ height: "20px", marginTop: "-4px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20px"
                      height="20px"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z" />
                    </svg>
                  </div>
                ) : (
                  <i
                    className={`icon ${
                      iconNamesByType[mediaType] || iconNamesByType[mediaType]
                    } mr-2`}
                  />
                )
              }
              {t(
                [`project:gallery.${mediaType}`, `poiPage.${mediaType}_count`],
                { count: medias.length }
              )}
            </Button>
          </Card.ImgOverlay>
        </Card>
      )}
    </AppContext.Consumer>
  );
}

POIGalleryButton.propTypes = {
  thumbnail: PropTypes.object,
  medias: PropTypes.arrayOf(PropTypes.object).isRequired,
  mediaType: PropTypes.oneOf([
    "photo",
    "video",
    "3d",
    "web",
    "pdf",
    "street_view",
  ]).isRequired,
};
POIGalleryButton.defaultProps = {};

export default POIGalleryButton;
