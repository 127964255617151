import React from 'react';
import {MapViewFilter as CoreMapViewFilter} from '@mappr/react-lib';
import { Dropdown } from 'react-bootstrap';
import MapViewFilterItems from './MapViewFilterItems';

const MapViewFilter = (props) => {

    return (
        <div className="d-inline-block">

            <CoreMapViewFilter>
                {({mapViewFilter}, {handleUpdateMapView}) => {
                        if (props.isInSubheader) {
                            return (
                            <Dropdown as="li" className="nav-item mx-2 position-relative">
                                <Dropdown.Toggle as="a" role="button"
                                                className={`nav-link btn btn-primary-lighter text-smaller`}>
                                    <i className="icon icon-location_pin"/>
                                </Dropdown.Toggle>
                                <MapViewFilterItems {...props} 
                                    onChange={handleUpdateMapView}
                                    options={mapViewFilter}
                                    containerId={'dropdown-map-views'}
                                    flipDropdown={true}/>
                            </Dropdown>
                            );
                        } else {
                            return (
                            <Dropdown className="d-inline-block mr-2">
                                <Dropdown.Toggle variant='primary-lighter' data-display="static">
                                <i className="icon icon-location_pin"/>
                                </Dropdown.Toggle>
                                <MapViewFilterItems {...props} 
                                    onChange={handleUpdateMapView}
                                    options={mapViewFilter}
                                    flipDropdown={false}/>
                            </Dropdown>
                            );
                        }
                    }
                }
            </CoreMapViewFilter>
        </div>
    )
}

MapViewFilter.propTypes = {

};

export default MapViewFilter;
