import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../context";
import { getDomain } from '../../../utils/envUtils';

function ConfigModal({basemaps, projectConfigs}) {
    const {
        domain,
        appConfigModalBasemapsEnabled,
        appConfigModalThemesEnabled,
        appConfigModalLayoutsEnabled,
    } = projectConfigs
    const {t} = useTranslation();
    const d = getDomain()

    const currentTheme = sessionStorage.getItem(`${d}-mpr-theme`);
    const currentLayout = sessionStorage.getItem(`${d}-mpr-layout`);
    const currentBaseMap = sessionStorage.getItem(`${d}-mpr-base-map`);

    const [theme, setTheme] = useState();
    const [layout, setLayout] = useState();
    const [baseMap, setBaseMap] = useState();

    useEffect(() => {
        setTheme(currentTheme)
        setLayout(currentLayout)
        setBaseMap(currentBaseMap)
    }, [currentTheme, currentLayout, currentBaseMap])

    const changeTheme = (event) => {
        setTheme(event.target.value);
    }


    const layoutList = [
        {
            layout: 'sidebarLeft',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="88" height="58"
                      viewBox="0 0 88 58">
                <g fill="none" fillRule="evenodd">
                    <rect className="dark-fill" width="88" height="58"
                          fill="#D4D4D5" rx="4"/>
                    <rect width="18" height="48" x="5" y="5" fill="#FFF"
                          rx="2"/>
                    <rect width="56" height="48" x="27" y="5" fill="#FFF"
                          opacity=".5" rx="2"/>
                    <rect width="12" height="12" x="8" y="8" fill="#E9E9E9"
                          rx="2"/>
                </g>
            </svg>
        },
        {
            layout: 'sidebarRight',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="88" height="58"
                      viewBox="0 0 88 58">
                <g fill="none" fillRule="evenodd">
                    <rect className="dark-fill" width="88" height="58"
                          fill="#D4D4D5" rx="4"/>
                    <rect width="56" height="48" x="5" y="5" fill="#FFF"
                          opacity=".5" rx="2"/>
                    <rect width="18" height="48" x="65" y="5" fill="#FFF"
                          rx="2"/>
                    <rect width="12" height="12" x="68" y="8" fill="#E9E9E9"
                          rx="2"/>
                </g>
            </svg>
        },
        {
            layout: 'headerLeft',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="88" height="58"
                      viewBox="0 0 88 58">
                <g fill="none" fillRule="evenodd">
                    <rect className="dark-fill" width="88" height="58"
                          fill="#D4D4D5" rx="4"/>
                    <rect width="18" height="39" x="5" y="14" fill="#FFF"
                          rx="2"/>
                    <rect width="56" height="39" x="27" y="14" fill="#FFF"
                          opacity=".5" rx="2"/>
                    <rect className="light-fill" width="78" height="6" x="5"
                          y="5" fill="#E9E9E9" opacity=".5" rx="2"/>
                </g>
            </svg>
        },
        {
            layout: 'headerRight',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="88" height="58"
                      viewBox="0 0 88 58">
                <g fill="none" fillRule="evenodd">
                    <rect className="dark-fill" width="88" height="58"
                          fill="#D4D4D5" rx="4"/>
                    <rect width="18" height="39" x="65" y="14" fill="#FFF"
                          rx="2"/>
                    <rect width="56" height="39" x="5" y="14" fill="#FFF"
                          opacity=".5" rx="2"/>
                    <rect className="light-fill" width="78" height="6" x="5"
                          y="5" fill="#E9E9E9" opacity=".5" rx="2"/>
                </g>
            </svg>
        },
        {
            layout: 'horizontal',
            svg: <svg xmlns="http://www.w3.org/2000/svg" width="88" height="58"
                      viewBox="0 0 88 58">
                <g fill="none" fillRule="evenodd">
                    <rect className="dark-fill" width="88" height="58"
                          fill="#D4D4D5" rx="4"/>
                    <rect width="78" height="9" x="5" y="44" fill="#FFF"
                          rx="2"/>
                    <rect width="78" height="35" x="5" y="5" fill="#FFF"
                          opacity=".5" rx="2"/>
                    <rect className="light-fill" width="72" height="6" x="8"
                          y="8" fill="#D4D4D5" opacity=".5" rx="2"/>
                </g>
            </svg>
        }
    ];
    return (
        <AppContext.Consumer>
            {({configModal, mprApiUrl}) => (
                <Modal id="modal-config" className="ConfigModal" show={configModal.open} centered={true}
                       onHide={configModal.handleClose}>
                    <Modal.Header>
                        <Modal.Title>
                            {t('modal.config.title')}
                        </Modal.Title>
                        <Button className="close text-muted" onClick={configModal.handleClose} data-dismiss="modal"
                                aria-label="Close">
                            <i className="icon icon-close"/>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        {appConfigModalThemesEnabled &&
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <p className="font-weight-bold mb-2">{t('modal.config.theme')}</p>
                                <div className="form-group">
                                    <div className="custom-control custom-radio d-inline-block mr-3">
                                        <p>
                                            <input type="radio" id="theme-radio-light"
                                                   name="theme-radio" value='light'
                                                   className="custom-control-input"
                                                   checked={theme === 'light'}
                                                   onChange={changeTheme}
                                            />
                                            <label className="custom-control-label"
                                                   htmlFor="theme-radio-light">Light</label>
                                        </p>
                                    </div>
                                    <div className="custom-control custom-radio d-inline-block">
                                        <p>
                                            <input type="radio" id="theme-radio-dark" name="theme-radio" value='dark'
                                                   className="custom-control-input"
                                                   checked={theme === 'dark'}
                                                   onChange={changeTheme}
                                            />
                                            <label className="custom-control-label"
                                                   htmlFor="theme-radio-dark">Dark</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {appConfigModalLayoutsEnabled &&
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="font-weight-bold mb-2">{t('modal.config.templates')}</p>
                                <div className="horizontal-scroll pt-1">
                                    {layoutList.map((layoutStyle, index) => (
                                        <div key={`layout-${index}`} onClick={() => setLayout(layoutStyle.layout)}
                                             className={`btn-layout layout-${index + 1} ${index < layoutList.length - 1 ? 'mr-3' : ''} ${layout === layoutStyle.layout ? 'active' : ''}`}>
                                            {layoutStyle.svg}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        }
                        {appConfigModalBasemapsEnabled &&
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="font-weight-bold mb-2">{t('modal.config.basemaps')}</p>
                                <div className="horizontal-scroll pt-1">
                                    {basemaps && basemaps.map(basemap => {
                                            return (
                                                <div key={basemap.id}
                                                     className={`rounded config-base-maps  ${baseMap === basemap.name ? 'active' : ''}`}
                                                     onClick={() => setBaseMap(basemap.name)}>
                                                    <img className={"w-100 h-100"}
                                                        src={`${mprApiUrl}/projects/common${basemap.icon?.filePath}`}/>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link"
                                onClick={configModal.handleClose}>{t('modal.config.buttons.cancel')}</Button>
                        <Button variant="primary"
                                onClick={() => {
                                    configModal.handleSave(theme, layout, baseMap)
                                }}
                        >{t('modal.config.buttons.save')}</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </AppContext.Consumer>

    );
}

ConfigModal.propTypes = {
    basemaps: PropTypes.array.isRequired,
    // currentBasemap: PropTypes.array.isRequired
};

export default ConfigModal;
