import React from 'react';
import PropTypes from 'prop-types';

function CardItemLoader (props) {
  return (
    <div className={`card bg-transparent ${props.compact ? 'card-compact h-100 w-14 border-0 p-1' : ''}`}>
      <div className="placeholder animated placeholder-rect rounded w-100"/>
      <div className="card-body px-0 py-1">
        <div className="card-title">
          <i className="icon icon-location_pin mr-1 placeholder animated" style={{ fontSize: '1.5rem' }}/>
          <div className="placeholder placeholder-line-short animated rounded d-inline-block"/>
        </div>
      </div>
    </div>
  );
}

CardItemLoader.propTypes = {
  compact: PropTypes.bool.isRequired
};
CardItemLoader.defaultProps = {
  compact: false
};

export default CardItemLoader;
