import React, { useEffect } from 'react';
import withRender from '../../hoc/withRender';
import PropTypes from 'prop-types';
var NavitiaSearchBox = function NavitiaSearchBox(props) {
  var onSubmit = function onSubmit(from, to) {
    props.onSubmit(from, to);
  };
  var onUpdate = function onUpdate(value) {
    props.onUpdate(value, props.addressLimit, props.stopAreaLimit);
  };
  var onRemove = function onRemove(value) {
    props.onRemove(value);
  };
  return props.render({
    searchText: props.text,
    journeys: props.journeys,
    loading: props.loading,
    error: props.error
  }, {
    onSearch: onSubmit,
    onUpdate: onUpdate,
    onRemove: onRemove
  });
};
NavitiaSearchBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  text: PropTypes.string,
  journeys: PropTypes.object,
  children: PropTypes.func,
  addressLimit: PropTypes.number.isRequired,
  stopAreaLimit: PropTypes.number.isRequired
};
NavitiaSearchBox.defaultProps = {
  text: '',
  journeys: {},
  addressLimit: 5,
  stopAreaLimit: 3,
  loading: true,
  error: false
};
export default withRender(NavitiaSearchBox);