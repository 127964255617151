import React from 'react';


export const getHash = (key, json = true) => {
  const hash = window.location.hash.replace("#", "")
  const params = new URLSearchParams(hash);
  if (key)
    return params.get(key) ? (json ? JSON.parse(params.get(key)) : params.get(key)) : null
  else
    return params.keys()
};

export const setHash = (key, value) => {
  const hash = window.location.hash.replace("#", "")
  const params = new URLSearchParams(hash);

  if (!value)
    params.delete(key)
  else
    params.set(key, JSON.stringify(value))

  window.location.hash = params.toString()
}