import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { useCallback } from "react";
import defaultImageUrl from "../../../assets/img/pattern.png";
function PopUp(props) {
  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(true);

  const {
    items,
    inPopup,
    onSeeMore,
    onClose,
    hasSeeMore,
    showClose,
    locale,
    mprApiUrl,
  } = props;
  const isCluster = items.length > 1;

  setTimeout(() => setCollapsed(false), 500);

  const handlePOIDetails = (item, locale) => {
    onSeeMore(item);
    if (props.history) {
      props.history.push({
        pathname: `/${locale}/poi`,
        hash: window.location.hash,
        state: { prevPath: props.history.location.pathname },
      });
    }
  };

  const { projectConfigs } = props;

  const getImageURL = useCallback(
    (domain, placeProps) => {
      let image =
        placeProps.mediasets &&
        placeProps.mediasets.main &&
        placeProps.mediasets.main.filePath
          ? placeProps.mediasets.main.filePath
              .split("/" + domain + "/")
              .join("")
          : null;

      if (!image) {
        image =
          projectConfigs &&
          projectConfigs.appLogos &&
          projectConfigs.appLogos[0] &&
          projectConfigs.appLogos[0].image.filePath
            ? projectConfigs.appLogos[0].image.filePath
                .split("/" + domain + "/")
                .join("")
            : null;
      }

      return image
        ? `${mprApiUrl}/projects/${domain}/image/256x256_cover/${image}`
        : defaultImageUrl;
    },
    [projectConfigs]
  );

  if (!items.length || !items[0].guid) {
    return null;
  }

  return (
    <div
      className={`MapPopup bg-white pt-1 ${isCluster ? "cluster" : ""} ${
        inPopup ? "rounded" : "rounded-top"
      } ${!inPopup && collapsed ? "collapsed" : ""}`}
    >
      <ul className="list-group list-group-flush w-100 h-100">
        {items.map((item) => {
          const { guid } = item;

          const iconValue = item[projectConfigs.iconFieldName];
          const iconMap = iconValue
            ? projectConfigs.iconMapping && projectConfigs.iconMapping.find((_) => _.value === iconValue)
            : null;
          let icon = iconMap
            ? iconMap.iconName
            : "material_ic_location_on_48px";

          if (icon && icon.match(/([0-9a-fA-F]{6})$/)) {
            icon = icon.substr(0, icon.lastIndexOf("_"));
          }
          const title =
            item[projectConfigs.poiTitleField] || ReactHtmlParser("&nbsp;");
          const subTitle = projectConfigs.poiSubtitleFields
            .map((field) => {
              return item[field.fieldName];
            })
            .join(",");

          if (item.mediasets && typeof item.mediasets !== "object")
            item.mediasets = JSON.parse(item.mediasets);

          let image = getImageURL(projectConfigs.domain, item);

          return (
            <li
              key={guid}
              className={`list-group-item media list-group-item-action d-block ${
                !isCluster ? "border-0" : ""
              } ${inPopup ? "pl-3 pr-2 overflow-hidden" : ""}`}
              onClick={
                isCluster ? () => handlePOIDetails({ guid }, locale) : null
              }
            >
              <img src={image} className="rounded align-top" alt="..." />
              <div
                className="media-body overflow-hidden d-inline-block"
                style={{ width: "70%" }}
              >
                <h5
                  className={`mt-0 ${inPopup ? "mb-2" : "mb-1 text-truncate"}`}
                >
                  <i
                    className={`icon text-primary align-middle mr-1 ic-${icon}`}
                  />
                  {title}
                </h5>
                <p
                  className={`text-dark ${inPopup ? "mb-2" : "text-truncate"}`}
                >
                  {subTitle}
                </p>
              </div>
              {!isCluster && showClose ? (
                <Button
                  className="close text-muted mt-1 mr-2 position-absolute"
                  style={{ top: 0, right: 0 }}
                  onClick={onClose}
                >
                  <i className="icon icon-close" />
                </Button>
              ) : null}
            </li>
          );
        })}
        {!isCluster ? (
          <React.Fragment>
            <Row className="mb-2 mx-0">
              <Col className="text-left"></Col>
              {hasSeeMore ? (
                <Col className="text-right">
                  <Button
                    variant="primary"
                    onClick={() =>
                      handlePOIDetails({ guid: items[0].id }, locale)
                    }
                    className={`more ${inPopup ? "" : "mr-3"}`}
                  >
                    {t("map.popup.buttons.seeMore")}
                  </Button>
                </Col>
              ) : null}
              {/*{!!onClose && <Col className="text-right">*/}
              {/*  <Button variant="link" className="font-weight-bold px-0"*/}
              {/*          onClick={onClose}>*/}
              {/*    {t('map.popup.buttons.close')}*/}
              {/*  </Button>*/}
              {/*</Col>*/}
              {/*}*/}
            </Row>
          </React.Fragment>
        ) : null}
      </ul>
    </div>
  );
}

PopUp.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      guid: PropTypes.string,
      image: PropTypes.object,
      iconType: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      ville: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,

  hasSeeMore: PropTypes.bool,
  onSeeMore: PropTypes.func,
  onClose: PropTypes.func,
  inPopup: PropTypes.bool,
  shortUrl: PropTypes.string,
  generateShortUrl: PropTypes.func,
};
PopUp.defaultProps = {
  hasSeeMore: true,
  inPopup: false,
};
export default PopUp;
