import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context";
import Header from "./Header/Header";
import Pages from "./pages/Pages";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./MobileLayout.scss";
import ShareModal from "../../components/modals/ShareModal/ShareModal";
import GalleryModal from "../../components/modals/GalleryModal/GalleryModal";
import StreetViewModal from "../../components/modals/StreetViewModal/StreetViewModal";

function MobileLayout({ loading, logout, project, basemaps }) {
  const { t } = useTranslation();

  if (loading) {
    return t("common:loading");
  }

  return (
    <AppContext.Consumer>
      {({ shareModal, galleryModal, streetViewModal }) => {
        return (
          <Router>
            <div className="MobileLayout ">
              <Header projectConfigs={project.configs} />
              <Switch>
                <Route
                  path="/:lang/"
                  exact
                  render={(routeProps) => (
                    <Pages project={project} basemaps={basemaps} />
                  )}
                />
                <Route
                  path="/:lang/:page"
                  render={(routeProps) => (
                    <Pages project={project} basemaps={basemaps} />
                  )}
                />
              </Switch>
              <ShareModal
                open={shareModal.open}
                onClose={shareModal.handleClose}
                shortUrl={shareModal.shortUrl}
              />
              <GalleryModal
                type={galleryModal.type}
                open={galleryModal.open}
                onClose={galleryModal.handleClose}
                medias={galleryModal.medias}
                className="full-screen"
              />
              <StreetViewModal
                open={streetViewModal.open}
                onClose={streetViewModal.handleClose}
                streetView={streetViewModal.medias[0]}
                withSidebar={false}
              />
            </div>
          </Router>
        );
      }}
    </AppContext.Consumer>
  );
}

MobileLayout.propTypes = {};

export default MobileLayout;
