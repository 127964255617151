import React from 'react';
import PropTypes from 'prop-types';
import CardItemLoader from './CardItemLoader';

function CardsLoader (props) {
  if (props.arrangement === 'horizontal') {
    return (
      <div className="flex-grow-1 w-100 horizontal-scroll pt-1 px-3">
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
        <CardItemLoader compact={true}/>
      </div>
    );
  } else {
    return (
      <div style={{ flex: '1 1', height: 'calc(100% - 40px)' }}>
        <div className="ListHeaderContents d-flex w-100 px-0 py-2">
          <div className="col-4 align-self-start">
            <div className="placeholder animated placeholder-line-slim rounded d-inline-block"/>
          </div>
          <div className="col-8 align-self-end text-right">
            <div className="placeholder animated placeholder-tiny-square rounded d-inline-block mr-2"/>
            <div className="placeholder animated placeholder-tiny-square rounded d-inline-block"/>
          </div>
        </div>
        <div className="px-3 overflow-auto w-100 pt-3" style={{ height: 'calc(100% - 40px)' }}>
          <div className="row">
            <div className="col-sm-12 col-md-6 mb-2 pl-md-1">
              <CardItemLoader/>
            </div>
            <div className="col-sm-12 col-md-6 mb-2 pr-md-1">
              <CardItemLoader/>
            </div>
            <div className="col-sm-12 col-md-6 mb-2 pl-md-1">
              <CardItemLoader/>
            </div>
            <div className="col-sm-12 col-md-6 mb-2 pr-md-1">
              <CardItemLoader/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CardsLoader.propTypes = {
  arrangement: PropTypes.oneOf(['horizontal', 'vertical'])
};
CardsLoader.defaultProps = {
  arrangement: 'vertical'
};

export default CardsLoader;
