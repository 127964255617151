import * as actionTypes from '../actionTypes';
import { DESELECT_JOURNEY_START } from '../actionTypes';

/**
 * The main action that is triggered when a new search query should be executed
 * @param text
 * @param {string} valueAction="add" One of ["add", "remove"]
 * @param masks
 * @returns {{type: string, payload: {dataSetId: *, text: *}}}
 */
export var navitiaSearch = function navitiaSearch(from, to) {
  return {
    type: actionTypes.NAVITIA_SEARCH_START,
    payload: {
      from: from,
      to: to
    }
  };
};
export var navitiaSearchDone = function navitiaSearchDone(payload) {
  return {
    type: actionTypes.NAVITIA_SEARCH_DONE,
    payload: payload
  };
};
export var navitiaSearchFail = function navitiaSearchFail(payload) {
  return {
    type: actionTypes.NAVITIA_SEARCH_FAIL,
    payload: payload
  };
};
export var journeyDeselectStart = function journeyDeselectStart() {
  return {
    type: actionTypes.DESELECT_JOURNEY_START
  };
};
export var journeyDeselectDone = function journeyDeselectDone() {
  return {
    type: actionTypes.DESELECT_JOURNEY_DONE
  };
};

/**
 * The main action that is triggered when a new search query should be executed
 * @param value
 * @param addressLimit
 * @param stopAreaLimit
 * @returns {{type: string, payload: {dataSetId: *, text: *}}}
 */
export var navitiaSuggest = function navitiaSuggest(value, addressLimit, stopAreaLimit) {
  return {
    type: actionTypes.NAVITIA_SUGGESTION_START,
    payload: {
      text: value,
      addressLimit: addressLimit,
      stopAreaLimit: stopAreaLimit
    }
  };
};
export var navitiaSuggestDone = function navitiaSuggestDone(payload) {
  return {
    type: actionTypes.NAVITIA_SUGGESTION_DONE,
    payload: payload
  };
};