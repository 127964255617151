function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { APP_INIT_DONE, APP_INIT_START, APP_LOAD_DONE, APP_LOAD_FAIL, APP_LOAD_START, APP_SET_LANGUAGE_DONE, APP_SET_LANGUAGE_START, APP_SET_LAYOUT_MODE_DONE, CHANGE_MAP_BBOX, MAP_DRAW, TOGGLE_LAYER_DONE } from '../../actionTypes';
var initialState = {
  loading: true,
  configs: {}
};
export default function appReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case APP_LOAD_START:
      {
        return _extends({}, state, {
          loading: true,
          configs: {}
        });
      }
    case APP_LOAD_DONE:
      {
        return _extends({}, state, {
          error: null,
          project: action.payload.project || null,
          basemaps: action.payload.basemaps || [],
          dataset: action.payload.datasets[0] || null,
          secondaryLayers: action.payload.secondaryLayers || [],
          activeLayers: action.payload.activeLayers || [],
          streetViewLayers: action.payload.streetViewLayers || [],
          mprApiUrl: action.payload.mprApiUrl,
          mprTileUrl: action.payload.mprTileUrl
        });
      }
    case APP_LOAD_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          configs: null,
          error: action.payload.error
        });
      }
    case APP_INIT_START:
      {
        return _extends({}, state, {
          loading: true
        });
      }
    case APP_INIT_DONE:
      {
        return _extends({}, state, {
          loading: false,
          language: action.payload.language
        });
      }
    case APP_SET_LANGUAGE_START:
      {
        return _extends({}, state);
      }
    case APP_SET_LANGUAGE_DONE:
      {
        return _extends({}, state, {
          // loading: false,
          language: action.payload.language
        });
      }
    case CHANGE_MAP_BBOX:
      {
        return _extends({}, state, {
          map: _extends({}, state.map, {
            bbox: action.payload.bbox,
            userMove: action.payload.userMove
          })
        });
      }
    case MAP_DRAW:
      {
        return _extends({}, state, {
          map: _extends({}, state.map, {
            draw: action.payload
          })
        });
      }
    case TOGGLE_LAYER_DONE:
      {
        return _extends({}, state, {
          activeLayers: action.payload.activeLayers || {}
        });
      }
    case APP_SET_LAYOUT_MODE_DONE:
      {
        return _extends({}, state, {
          isMobile: action.payload || false
        });
      }
    default:
      return state;
  }
}