export var getProjectId = function getProjectId(state) {
  return state.app && state.app.project ? state.app.project.id : null;
};
export var getProjectDomain = function getProjectDomain(state) {
  return state.app && state.app.project ? state.app.project.configs.domain : null;
};
export var getItemsLimit = function getItemsLimit(state) {
  return state.app && state.app.project ? state.app.project.configs.itemsLimit : null;
};
export var getFilterOrderAlpha = function getFilterOrderAlpha(state) {
  return state.app && state.app.project ? state.app.project.configs.filterOrderAlpha : null;
};
export var getProjectUid = function getProjectUid(state) {
  return state.app && state.app.project ? state.app.project.uid : null;
};
export var getDataset = function getDataset(state) {
  return state.app && state.app.dataset ? state.app.dataset : null;
};
export var getDatasetId = function getDatasetId(state) {
  return state.app && state.app.dataset ? state.app.dataset.id : null;
};
export var getCurrentLanguage = function getCurrentLanguage(state) {
  return state.app && state.app.language ? state.app.language : null;
};
export var getSearchType = function getSearchType(state) {
  return state.app && state.app.project ? state.app.project.configs.searchType : null;
};
export var getIsBboxIncluded = function getIsBboxIncluded(state) {
  return state.app && state.app.project ? state.app.project.configs.isBboxIncluded : true;
};
export var getDefaultLanguage = function getDefaultLanguage(state) {
  return state.app && state.app.project && state.app.project.configs && state.app.project.configs.defaultLocale ? state.app.project.configs.defaultLocale.value : null;
};
export var getIndexes = function getIndexes(state) {
  return state.app && state.app.dataset ? state.app.dataset.indexes : null;
};
export var getFiltersSelectedValues = function getFiltersSelectedValues(state) {
  return (state === null || state === void 0 ? void 0 : state.dataset) && state.dataset.filters ? state.dataset.filters.selectedValues : null;
};
export var getFiltersMap = function getFiltersMap(state) {
  if ((state === null || state === void 0 ? void 0 : state.dataset) && state.dataset.filters && !state.dataset.filters.loading) {
    var filtersMap = {};
    state.dataset.filters.filters.forEach(function (_) {
      filtersMap[_.name] = _;
    });
    return filtersMap;
  } else {
    return null;
  }
};
export var getSearchMasks = function getSearchMasks(state) {
  var _state$dataset$search, _state$dataset, _state$dataset$search2;
  return (_state$dataset$search = state === null || state === void 0 ? void 0 : (_state$dataset = state.dataset) === null || _state$dataset === void 0 ? void 0 : (_state$dataset$search2 = _state$dataset.search) === null || _state$dataset$search2 === void 0 ? void 0 : _state$dataset$search2.masks) !== null && _state$dataset$search !== void 0 ? _state$dataset$search : null;
};
export var getCurrentBbox = function getCurrentBbox(state) {
  var _state$app$map$bbox, _state$app, _state$app$map;
  return (_state$app$map$bbox = state === null || state === void 0 ? void 0 : (_state$app = state.app) === null || _state$app === void 0 ? void 0 : (_state$app$map = _state$app.map) === null || _state$app$map === void 0 ? void 0 : _state$app$map.bbox) !== null && _state$app$map$bbox !== void 0 ? _state$app$map$bbox : null;
};
export var getActiveLayers = function getActiveLayers(state) {
  var _state$app$activeLaye, _state$app2;
  return (_state$app$activeLaye = state === null || state === void 0 ? void 0 : (_state$app2 = state.app) === null || _state$app2 === void 0 ? void 0 : _state$app2.activeLayers) !== null && _state$app$activeLaye !== void 0 ? _state$app$activeLaye : null;
};
export var getSelectedFilterLogic = function getSelectedFilterLogic(state) {
  var _state$app$project$co, _state$app3, _state$app3$project, _state$app3$project$c;
  return (_state$app$project$co = state === null || state === void 0 ? void 0 : (_state$app3 = state.app) === null || _state$app3 === void 0 ? void 0 : (_state$app3$project = _state$app3.project) === null || _state$app3$project === void 0 ? void 0 : (_state$app3$project$c = _state$app3$project.configs) === null || _state$app3$project$c === void 0 ? void 0 : _state$app3$project$c.filterLogic) !== null && _state$app$project$co !== void 0 ? _state$app$project$co : 'and';
};
export var getSelectedSuggestionsLogic = function getSelectedSuggestionsLogic(state) {
  var _state$app$project$co2, _state$app4, _state$app4$project, _state$app4$project$c;
  return (_state$app$project$co2 = state === null || state === void 0 ? void 0 : (_state$app4 = state.app) === null || _state$app4 === void 0 ? void 0 : (_state$app4$project = _state$app4.project) === null || _state$app4$project === void 0 ? void 0 : (_state$app4$project$c = _state$app4$project.configs) === null || _state$app4$project$c === void 0 ? void 0 : _state$app4$project$c.suggestionsLogic) !== null && _state$app$project$co2 !== void 0 ? _state$app$project$co2 : 'and';
};
export var getGeocoderSeparatePOIs = function getGeocoderSeparatePOIs(state) {
  var _state$app5, _state$app5$project, _state$app5$project$c;
  return state === null || state === void 0 ? void 0 : (_state$app5 = state.app) === null || _state$app5 === void 0 ? void 0 : (_state$app5$project = _state$app5.project) === null || _state$app5$project === void 0 ? void 0 : (_state$app5$project$c = _state$app5$project.configs) === null || _state$app5$project$c === void 0 ? void 0 : _state$app5$project$c.geocoderSeparatePOIs;
};
export var getGeocoderCountries = function getGeocoderCountries(state) {
  var _state$app6, _state$app6$project, _state$app6$project$c;
  return state === null || state === void 0 ? void 0 : (_state$app6 = state.app) === null || _state$app6 === void 0 ? void 0 : (_state$app6$project = _state$app6.project) === null || _state$app6$project === void 0 ? void 0 : (_state$app6$project$c = _state$app6$project.configs) === null || _state$app6$project$c === void 0 ? void 0 : _state$app6$project$c.geocoderCountries;
};
export var getCurrentOrder = function getCurrentOrder(state) {
  var _state$dataset2, _state$dataset2$data;
  return state === null || state === void 0 ? void 0 : (_state$dataset2 = state.dataset) === null || _state$dataset2 === void 0 ? void 0 : (_state$dataset2$data = _state$dataset2.data) === null || _state$dataset2$data === void 0 ? void 0 : _state$dataset2$data.orderBy;
};