import React, { useEffect, useReducer, useState } from "react";
import DetectBrowser from "react-detect-browser";
import { useParams } from "react-router-dom";
import { Mappr } from "@mappr/react-lib";
import "./App.scss";
import OldBrowserMessage from "./components/OldBrowserMessage/OldBrowserMessage";
import { AppContext, MapContext } from "./context";
import i18n from "./i18n";
import { Redirect } from "react-router";
import FullPageLoading from "./components/loaders/FullPageLoading/FullPageLoading";
import { reducer } from "./App.store";
import MainContainer from "./components/MainContainer/MainContainer";
import { useTranslation } from "react-i18next";
import { getApiUrl, getDomain } from "./utils/envUtils";
import withSocialScripts from "./hoc/withSocialScripts";
import { Helmet } from "react-helmet";

function addStyleElement(id, url) {
  return new Promise((resolve, reject) => {
    const elm = document.createElement("link");
    elm.type = "text/css";
    elm.rel = "stylesheet";
    elm.href = url;
    elm.id = id;
    elm.onload = () => {
      resolve();
    };
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(elm);
  });
}

function removeStyleElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.parentNode.removeChild(element);
  }
}

function sortImages(a, b) {
  const nameA = a.original.substring(a.original.lastIndexOf("/")).toUpperCase(); // ignore upper and lowercase
  const nameB = b.original.substring(b.original.lastIndexOf("/")).toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

function App({ match }) {
  const [domain, setDomain] = useState();
  const { lang } = useParams();
  const { t } = useTranslation();

  const currentTheme = sessionStorage.getItem(`${getDomain()}-mpr-theme`);
  const activeLayout = sessionStorage.getItem(`${getDomain()}-mpr-layout`);
  const activeBaseMap = sessionStorage.getItem(`${getDomain()}-mpr-base-map`);

  const customizeApp = (theme, layout, baseMap) => {
    const d = getDomain();
    if (activeLayout !== layout) {
      sessionStorage.setItem(`${d}-mpr-layout`, layout);
    }
    if (activeBaseMap !== baseMap) {
      sessionStorage.setItem(`${d}-mpr-base-map`, baseMap);
    }
    if (currentTheme !== theme) {
      sessionStorage.setItem(`${d}-mpr-theme`, theme);
    }
    window.location.reload();
  };

  const setGalleryMedias = (medias, type) => {
    setAppState({
      prop: "galleryModal",
      value: {
        ...appState.galleryModal,
        medias: type === "photo" ? medias.sort(sortImages) : medias,
        type,
        open: true,
      },
    });
  };

  const setStreetViewMedias = (medias) => {
    setAppState({
      prop: "streetViewModal",
      value: {
        ...appState.streetViewModal,
        medias: medias,
        open: true,
      },
    });
  };

  const [appState, setAppState] = useReducer(reducer, {
    domain: "",
    theme: currentTheme,
    stylesLoaded: false,
    mprApiUrl: getApiUrl("api"),
    mprGqlUrl: getApiUrl("graphql"),
    mprTileUrl: getApiUrl("tile"),
    layout: activeLayout || "sidebarLeft",
    baseMap: activeBaseMap || "base-map-2",
    projectConfigs: null,
    locale: {
      value: lang,
      setValue: setLocale,
    },
    listViewType: {
      handleChange: (type) => handleResultViewTypeChange(type),
      value: "card",
    },
    configModal: {
      open: false,
      handleOpen: () => toggleModal("configModal", true),
      handleClose: () => toggleModal("configModal", false),
      handleSave: customizeApp,
    },
    shareModal: {
      open: false,
      handleOpen: () => toggleModal("shareModal", true),
      handleClose: () => toggleModal("shareModal", false),
      shortUrl: "",
    },
    galleryModal: {
      open: false,
      medias: [],
      type: "photo",
      handleOpen: () => toggleModal("galleryModal", true),
      handleClose: () => toggleModal("galleryModal", false),
      setMedias: setGalleryMedias,
    },
    streetViewModal: {
      open: false,
      medias: [],
      type: "street_view",
      handleOpen: () => toggleModal("streetViewModal", true),
      handleClose: () => toggleModal("streetViewModal", false),
      setMedias: setStreetViewMedias,
    },
  });

  const setMapItem = (layerId, type, item, methods) => {
    // if (type !== 'hovered' && type !== 'selected') {
    //     return;
    // }
    // this.setState(prevState => ({
    //     map: {
    //         ...prevState.map,
    //         feature: {
    //             ...prevState.map.feature,
    //             layerId,
    //             hovered: type === 'selected' ? {
    //                 item: null, popupElement: null, methods: {}
    //             } : null,
    //             [type]: {
    //                 ...prevState.map.feature[type],
    //                 item,
    //                 methods
    //             }
    //         }
    //     }
    // }));
  };

  const setMapPopupElement = (type, popupElement) => {
    if (type !== "hovered" && type !== "selected") {
      return;
    }

    // this.setState(prevState => ({
    //     map: {
    //         ...prevState.map,
    //         feature: {
    //             ...prevState.map.feature,
    //             [type]: {
    //                 ...prevState.map.feature[type],
    //                 popupElement,
    //             }
    //         }
    //     }
    // }));
  };

  const [mapState, setMapState] = useReducer(reducer, {
    feature: {
      hovered: {
        item: null,
        popupElement: null,
        methods: {},
      },
      selected: {
        item: null,
        popupElement: null,
        methods: {},
      },
      setMapItem,
      setMapPopupElement,
    },
  });

  useEffect(() => {
    if (!appState.theme) return;

    addStyleElement(
      "mpr-ui-theme",
      `${process.env.REACT_APP_MPR_CSS_URL}/${
        appState.projectConfigs ? getDomain() : "vectuel-demo"
      }/css/theme-${appState.theme}.css`
    ).then((r) => {
      setAppState({ prop: "stylesLoaded", value: true });
    });
  }, [appState.theme, domain, appState.projectConfigs]);

  function setLocale(newLang) {
    window.location.replace(getLocaleUrl(newLang));
  }

  function getLocaleUrl(newLang) {
    const search = new URLSearchParams(window.location.search);
    let url = window.location.pathname.replace(match.url, "/" + newLang);
    url += "?" + search.toString();
    url += window.location.hash;

    console.log("Redirect To: ", url);
    // ?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiIyNzlhMWY2Yy1iZjE2LTQwNmQtYmE0MC1hYzhlZDY2NDJkMDkiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9iZTBiZTA5My1hMmFkLTQ0NGMtOTNkOS01NjI2ZTgzYmVlZmMvIiwiaWF0IjoxNjYxMjQyMDIxLCJuYmYiOjE2NjEyNDIwMjEsImV4cCI6MTY2MTI0NTkyMSwiYWlvIjoiQVNRQTIvOFRBQUFBKzJpNmt5elFnVDhRdjN0YjlOZkM5THAxSTY3dGY5K3RIcmNxekkyNVVZST0iLCJhbXIiOlsicHdkIl0sImNjIjoiQ21BbzdPT28rRGpUZ0dGUFhhaEFQdWgyZjNSeElOdHRzNDArTWN1SVpxekZnbTFKMnJYei9YTVYycllVSi9Ec0QyYjJDcGhuc25NejdMRFhocWV1SUU5eWxyTTRWcEF0Vjcwc1RQenNMcnNEVWxGdFkwSllXb0VXQkR0L2U5Ni9ZYU1TQ1dOdmJHRnpMbU52YlJvU0NoQXgyRmxWWkZvRVQ3M0R2Z2gxOUV3SEloSUtFSWszYVJoUUNPaEFsa2R2LzhLV0l3QW9BVElDUlZVNEFVSUpDWUFmSEdzWVF0cEkiLCJmYW1pbHlfbmFtZSI6IkZSQU5DT0lTIiwiZ2l2ZW5fbmFtZSI6IlRlaWxvIiwiaXBhZGRyIjoiNzguMTA5LjY5LjI0OSIsIm5hbWUiOiJGUkFOQ09JUywgVGVpbG8gLSBFeHQgKENPTEFTIERJR0lUQUwgU09MVVRJT05TKSIsIm5vbmNlIjoiZDNkNzEzYzAtNGMyYy00YWE0LWFkZjQtOTgzYWFmNTJkNzQyIiwib2lkIjoiYjdmNjgyMWEtZTAxMS00YmRiLWFmMWMtNzgyZmY5OTEwYWJlIiwib25wcmVtX3NpZCI6IlMtMS01LTIxLTM1MjEzOTQwMzItMjMxODk1NjAwNS0zNjQ5MjkyNTI5LTE1MjE0MzgiLCJyaCI6IjAuQVFJQWstQUx2cTJpVEVTVDJWWW02RHZ1X0d3Zm1pY1d2MjFBdWtDc2p0WmtMUWtDQU5ZLiIsInN1YiI6IjFVRGtjcUpDeXVqNjlKWVQ4R2NydVh0Q1poOTdUZlQ0dC0tNnhmMmFYdm8iLCJ0aWQiOiJiZTBiZTA5My1hMmFkLTQ0NGMtOTNkOS01NjI2ZTgzYmVlZmMiLCJ1bmlxdWVfbmFtZSI6IkZSQU5DT1RAY29sYXMuY29tIiwidXBuIjoiRlJBTkNPVEBjb2xhcy5jb20iLCJ1dGkiOiJpVGRwR0ZBSTZFQ1dSMl9fd3BZakFBIiwidmVyIjoiMS4wIn0.NqCgf0e12-HwKQlIXOY1ipDK4sT968jBV1vxg6Ocl_0XVo3rSKXduc96B74s1awafJIclnfgYw5yaDcMAO-VkdFydOXgrZwiMwwdZ49f-jOvgiTpMQ7RdvIdECi9zZJanvnQcjwSyw3R-xfY9g4AahJ4YVCWWLwHlL87QV9FmIFTkE6WlIt7-i8YIgk0wlWKlbJt9wKo16eXx6k7AwnePaVjRNMWWbvM381QMxH3lLbUw6VSL3pG4GZyu-yb8oY24HveRbusxjad17bh4g5IzfOVFkk1y7w54UbZt8M2KE-bCbdu_LAHsuSaNTw0afE4pvkTCG4flA8YmU50r1m-rg

    return url;
  }

  useEffect(() => {
    setAppState({ prop: "locale", value: lang });
  }, [match]);

  useEffect(() => {
    const d = getDomain();
    setDomain(d);
    setAppState({ prop: "domain", value: d });

    const elements = document.head.querySelectorAll(
      'link[href^="/static/css/theme-"], script[src^="/static/js/theme-"]'
    );

    elements.forEach((element) => {
      element.parentNode.removeChild(element);
    });

    removeStyleElement("style-pre-load");

    i18n.changeLanguage(appState.locale.value);

    document.addEventListener("select-pegman", (event) => {
      setStreetViewMedias([{ embedUrl: event.detail.url }]);
    });
    return () => {};
  }, []);

  useEffect(() => {
    i18n.changeLanguage(appState.locale.value);
    return () => {};
  }, [appState.locale.value]);

  const toggleModal = (modal, open) => {
    setAppState({
      prop: modal,
      value: {
        ...appState[modal],
        open: open,
      },
    });
  };

  const handleResultViewTypeChange = (viewType) => {
    setAppState({ prop: "listViewType", value: viewType });
  };

  return (
    <DetectBrowser>
      {({ browser }) =>
        (browser.os === "iOS" && parseFloat(browser.version) <= 10) ||
        browser.name === "ie" ||
        (browser.name === "chrome" && parseFloat(browser.version) <= 58) ||
        (browser.name === "firefox" && parseFloat(browser.version) <= 46) ? (
          <OldBrowserMessage />
        ) : (
          <AppContext.Provider value={appState}>
            <MapContext.Provider value={mapState}>
              <div
                className="App"
                data-layout={appState.layout}
                data-theme={appState.theme}
              >
                <Helmet>
                  <title>{`${
                    (appState?.projectConfigs?.appName &&
                      appState?.projectConfigs?.appName[lang]) ||
                    "Mappr"
                  }`}</title>
                </Helmet>
                {domain && (
                  <Mappr
                    name={domain}
                    mprApiUrl={appState.mprApiUrl}
                    mprGqlUrl={appState.mprGqlUrl}
                    mprTileUrl={appState.mprTileUrl}
                    language={appState.locale.value || "en"}
                  >
                    {(
                      {
                        loading,
                        authenticated,
                        error,
                        authError,
                        language,
                        project,
                        basemaps,
                        draw,
                      },
                      { login, logout, setLanguage }
                    ) => {
                      if (loading) {
                        return <FullPageLoading />;
                      }

                      if (
                        project &&
                        ((!lang && project.configs.defaultLocale) ||
                          (lang &&
                            !project.configs.locales.find(
                              (_) => _.value === lang
                            )))
                      ) {
                        return (
                          <Redirect
                            exact={true}
                            to={getLocaleUrl(
                              project.configs.defaultLocale.value
                            )}
                          />
                        );
                      }

                      return (
                        <MainContainer
                          appState={appState}
                          setAppState={setAppState}
                          project={project}
                          authenticated={authenticated}
                          basemaps={basemaps}
                          domain={domain}
                          loading={loading}
                          logout={logout}
                          login={login}
                          draw={draw}
                          error={
                            (!authenticated &&
                              project.configs.authType === "sso" &&
                              authError) ||
                            error
                          }
                          authError={authError}
                        />
                      );
                    }}
                  </Mappr>
                )}
              </div>
            </MapContext.Provider>
          </AppContext.Provider>
        )
      }
    </DetectBrowser>
  );
}

export default withSocialScripts(App);
