function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DATA_ITEMS_LOAD_DONE, DATA_ITEMS_LOAD_FAIL, DATA_ITEMS_LOAD_START, DATA_ITEMS_ORDER_SET } from '../../actionTypes/datasetSlice';
var initialState = {
  loading: true,
  items: [],
  info: {},
  orderBy: null
};
export default function itemsReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case DATA_ITEMS_LOAD_START:
      {
        return _extends({}, state, {
          loading: true
        });
      }
    case DATA_ITEMS_LOAD_DONE:
      {
        return _extends({}, state, {
          loading: false,
          error: null,
          items: action.payload.items,
          info: action.payload.info
        });
      }
    case DATA_ITEMS_LOAD_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          error: action.payload.error
        });
      }
    case DATA_ITEMS_ORDER_SET:
      {
        return _extends({}, state, {
          orderBy: action.payload.orderBy
        });
      }
    default:
      return state;
  }
}