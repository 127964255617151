import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../../hoc/withRender';
function SelectedItem(props) {
  var loading = props.loading,
    error = props.error,
    item = props.item,
    deselect = props.deselect;
  return props.render({
    loading: loading,
    error: error,
    item: item
  }, {
    deselect: deselect
  });
}
SelectedItem.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  item: PropTypes.object
};
export default withRender(SelectedItem);