function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DATA_ITEM_HOVER, DATA_ITEM_HOVER_DONE, DATA_ITEM_HOVER_OFF } from '../../actionTypes/datasetSlice';
var initialState = {
  item: null
};
export default function hoveredItemReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case DATA_ITEM_HOVER_DONE:
      {
        return _extends({}, state, {
          item: action.payload.item
        });
      }
    case DATA_ITEM_HOVER_OFF:
      {
        return _extends({}, state, {
          item: null
        });
      }
    default:
      return state;
  }
}