import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { AppContext } from '../../../../context';
import ReactDOM from "react-dom";

const MapViewFilterItems = ({ options, flipDropdown, onChange, containerId }) => {

  const dropdownMenuContents = (
    <AppContext.Consumer>
      {({ layout }) => (
        <Dropdown.Menu as="ul"
                       className={'filter-dropdown list-group list-group-flush py-0 scrollable'}
                       flip={true}>
          {options.map((option, index) => (
            <Dropdown.Item key={index} onClick={() => onChange(option.label)}
                           className={`list-group-item d-flex justify-content-between align-items-center`}>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )
      }
    </AppContext.Consumer>
  );

  if (containerId) {
    let container = document.getElementById(containerId);
    if (!container) {
      container = document.createElement('div');
      container.id = containerId;
      container.style.position = 'absolute';
      container.style.left = 0;
      container.style.top = 0;
      container.style.zIndex = '9999';
      container.style.transform = 'translate3D(0px,32px,0px)';
      document.body.appendChild(container);
    }

    return ReactDOM.createPortal(
        dropdownMenuContents,
        container,
    );
  } else {
    return dropdownMenuContents;
  }
}

MapViewFilterItems.propTypes = {};
MapViewFilterItems.defaultProps = {};

export default MapViewFilterItems;
