import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import {Button, Modal} from 'react-bootstrap';
import PDFViewer from './PDFViewer/PDFViewer';
import './GalleryModal.scss';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../context";


const GalleryModal = (props) => {
    const {t} = useTranslation();

    const {medias, type, open, onClose, className} = props

    const [currentSlide, setCurrentSlide] = useState(1)
    const [showVideo, setShowVideo] = useState({})
    const [iconType, setIconType] = useState('photo')
    useEffect(() => {
        if (type === 'web') {
            setIconType('www')
        } else if (type === '3d') {
            setIconType('three_d')
        } else if (type === 'video') {
            setIconType('play')
        }
    }, [])

    useEffect(() => {
        setCurrentSlide(1)
        setShowVideo({})
    }, [medias, type]);

    useEffect(() => {
        const container = document.querySelector('.image-gallery-thumbnails');
        if (container) {
            const containerWidth = container.offsetWidth;
            const thumbWidth = container.offsetWidth;
            const visibleImagesCount = containerWidth / (thumbWidth + 15);

            if (currentSlide > visibleImagesCount) {
                document.querySelector('.image-gallery-thumbnails-container').scrollTo({
                    left: (currentSlide + 1) * (thumbWidth + 15),
                    behavior: 'smooth'
                });
            } else if (currentSlide * (thumbWidth + 15) <= document.querySelector('.image-gallery-thumbnails-container').scrollLeft) {
                document.querySelector('.image-gallery-thumbnails-container').scrollTo({
                    left: (currentSlide - 1) * (thumbWidth + 15),
                    behavior: 'smooth'
                });
            }
        }
    }, [currentSlide, showVideo])

    const renderNavButton = (position, onClick, disabled) => (
        <Button variant="link"
                className={`image-gallery-custom-${position}-nav text-white`}
                disabled={disabled}
                onClick={onClick}>
            <i className={`icon icon-slider_arrow_${position}`}/>
        </Button>
    );

    const renderVideo = (item) => (
        <div className='image-gallery-image'>
            {
                <div className="video-wrapper d-flex w-100 h-100">
                    <iframe
                        title={item.embedUrl}
                        width="100%"
                        height="auto"
                        src={item.embedUrl}
                        frameBorder="0"
                        allowFullScreen
                    />
                </div>
            }
        </div>
    );

    const renderPdf = (item) => (
        <AppContext.Consumer>
            {({projectConfigs, mprApiUrl}) => (
                <div className='image-gallery-image' key={item.original}>
                    <div className="d-flex w-100 h-100">
                        <PDFViewer
                            file={item.url ? item.url : `${mprApiUrl}/projects/${projectConfigs.domain}/asset/data/pdfs/${item.fileName}`}/>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );

    const onSlide = (currentIndex) => {
        setCurrentSlide(currentIndex + 1)
        setShowVideo({})
    };


    return (
        <Modal show={open} dialogClassName={`MediaGallery modal-90w modal-90h ${className}`}>
            <Modal.Header className="p-0 pb-2 text-white">
                <div className="col align-self-start pl-0 text-left">
                    <i className={`icon icon-${iconType} mr-2`}/>
                    {medias.length} <span
                    className="d-none d-md-inline">{t(`gallery.${type}`, {count: medias.length})}</span>
                </div>
                {medias.length && (<div className="col align-self-center text-center">
                    <h5 className="font-weight-normal d-inline d-md-none">{medias[currentSlide - 1] && medias[currentSlide - 1].originalTitle}</h5>
                    <h4 className="font-weight-normal d-none d-md-inline">{medias[currentSlide - 1] && medias[currentSlide - 1].originalTitle}</h4>
                </div>)}
                <div className="col align-self-start pr-0 text-right">
                    <Button variant="link" className="text-white p-0" onClick={onClose}>
                        <i className="icon icon-close"/>
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="image-gallery-backdrop"/>
                <ImageGallery items={medias.map(item => {
                    let mediaItem = {...item};
                    if (item.embedUrl && type !== 'pdf') {
                        mediaItem.renderItem = renderVideo;
                    } else if (type === 'pdf') {
                        mediaItem.renderItem = renderPdf;
                    }
                    return mediaItem;
                })} showFullscreenButton={false} showPlayButton={false}
                              renderLeftNav={(onClick, disabled) => renderNavButton('left', onClick, disabled)}
                              renderRightNav={(onClick, disabled) => renderNavButton('right', onClick, disabled)}
                              disableThumbnailScroll={true}
                />
            </Modal.Body>
        </Modal>
    );
}

GalleryModal.propTypes = {
    medias: PropTypes.array,
    type: PropTypes.oneOf(['photo', 'video', '3d', 'web', 'pdf']).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string
};
GalleryModal.defaultProps = {
    type: 'photo',
    open: false,
    medias: [],
    className: ''
};

export default GalleryModal;
