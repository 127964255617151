import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
import { useSelector } from 'react-redux';
var Suggestions = function Suggestions(props) {
  var _useSelector = useSelector(function (state) {
      return state.geocoder;
    }),
    list = _useSelector.list,
    poisList = _useSelector.poisList;
  var onSelect = function onSelect(value, isGeocoder) {
    props.onSelect(value, isGeocoder);
  };
  return props.render({
    suggestions: props.suggestions.slice(0),
    geocoderResults: list.slice(0, props.limit),
    geocoderPOIsResults: poisList.slice(0, props.limit)
  }, {
    onSelect: onSelect
  });
};
Suggestions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
  geocoderResults: PropTypes.array,
  geocoderPOIsResults: PropTypes.array,
  children: PropTypes.func
};
export default withRender(Suggestions);