import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl } from 'react-bootstrap';

function SearchAndFiltersLoader (props) {
  return (
    <>
      <div className="row h-100 px-3">
        <div className="col-12 align-self-start pt-3">
          <div className="form-group form-group-custom-autocomplete position-relative z-index-1">
            <FormControl
              className={`placeholder animated ${props.isInSubheader ? 'bg-primary-darkest border-primary-darkest' : ''}`}
              disabled
            />
          </div>
          <div>
            <Button variant="link" className="placeholder animated bg-grey mr-2" disabled>&nbsp;</Button>
            <Button variant="link" className="placeholder animated bg-grey" disabled>&nbsp;</Button>
          </div>
        </div>
        {props.showFooter ? (
          <div className="col-12 align-self-end pb-3">
            <Button variant="link" className="placeholder animated bg-grey btn-lg btn-block w-100"
                    disabled>&nbsp;</Button>
          </div>
        ) : null}
      </div>
    </>
  );
}

SearchAndFiltersLoader.propTypes = {
  showFooter: PropTypes.bool
};
SearchAndFiltersLoader.defaultProps = {
  showFooter: true
};

export default SearchAndFiltersLoader;
