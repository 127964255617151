import React from 'react';
function GeocoderPopup (props) {
  const { item, onClose } = props;
  return (
    <div
      className="MapPopup bg-white pt-1 rounded">
      <ul className="list-group list-group-flush w-100 h-100">
        <li className="list-group-item media list-group-item-action d-sm-block d-lg-flex border-0 pl-3 pr-2 overflow-hidden">
          <div className="media-body overflow-hidden d-inline-block w-100">
            <h5 className="mt-0 mb-2">{item.text}</h5>
          </div>
          <button className="close text-muted mt-n2" onClick={onClose}>
            <i className="icon icon-close"/>
          </button>
        </li>
      </ul>
    </div>
  );
}

GeocoderPopup.propTypes = {};
GeocoderPopup.defaultProps = {};

export default GeocoderPopup;
