function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DESELECT_JOURNEY_DONE, DESELECT_JOURNEY_START, NAVITIA_SEARCH_DONE, NAVITIA_SEARCH_FAIL, NAVITIA_SEARCH_START, NAVITIA_SUGGESTION_DONE, NAVITIA_SUGGESTION_FAIL, NAVITIA_SUGGESTION_START, REMOVE_JOURNEY_DONE } from '../../actionTypes';
var initialState = {
  text: '',
  error: null,
  suggestions: [],
  journeys: {},
  loading: false
};
export default function navitiaSearchReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case NAVITIA_SEARCH_START:
      return _extends({}, state, {
        loading: true,
        text: action.payload.text
      });
    case NAVITIA_SEARCH_DONE:
      return _extends({}, state, {
        loading: false,
        journeys: action.payload,
        error: null
      });
    case NAVITIA_SEARCH_FAIL:
      return _extends({}, state, {
        loading: false,
        error: action.payload.error,
        journeys: {}
      });
    case NAVITIA_SUGGESTION_START:
      return _extends({}, state, {
        loading: true,
        text: action.payload.text
      });
    case NAVITIA_SUGGESTION_DONE:
      return _extends({}, state, {
        loading: false,
        suggestions: action.payload.suggestions
      });
    case NAVITIA_SUGGESTION_FAIL:
      return _extends({}, state, {
        loading: false,
        suggestions: []
      });
    case DESELECT_JOURNEY_START:
      return _extends({}, state, {
        loading: true,
        error: null
      });
    case DESELECT_JOURNEY_DONE:
      return _extends({}, state, {
        loading: false,
        suggestions: [],
        journeys: {}
      });
    default:
      return state;
  }
}