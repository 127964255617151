import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function MapLegend(props) {
  var showLegend = props.showLegend,
    legendItems = props.legendItems,
    isMapLegendOpen = props.isMapLegendOpen,
    legendTitle = props.legendTitle;
  return props.render({
    showLegend: showLegend,
    legendItems: legendItems,
    isMapLegendOpen: isMapLegendOpen,
    legendTitle: legendTitle
  });
}
MapLegend.propTypes = {
  showLegend: PropTypes.bool,
  legendItems: PropTypes.array
};
export default withRender(MapLegend);