import React from 'react';
import withRender from '../../hoc/withRender';
var MapViewFilter = function MapViewFilter(props) {
  return props.render({
    mapViewFilter: props.mapViewFilter
  }, {
    handleUpdateMapView: function handleUpdateMapView(mapViewFilterName) {
      return props.handleUpdateMapView(mapViewFilterName);
    }
  });
};
export default withRender(MapViewFilter);