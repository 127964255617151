function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { AUTH_LOGIN_START, AUTH_LOGIN_DONE, AUTH_LOGIN_FAIL, AUTH_LOGOUT_START, AUTH_LOGOUT_DONE, AUTH_LOGOUT_FAIL } from '../actionTypes';
export var login = function login(payload) {
  return {
    type: AUTH_LOGIN_START,
    payload: {
      username: payload.username,
      password: payload.password
    }
  };
};
export var loginDone = function loginDone(payload) {
  return {
    type: AUTH_LOGIN_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var loginFail = function loginFail(payload) {
  return {
    type: AUTH_LOGIN_FAIL,
    payload: payload
  };
};
export var logout = function logout(payload) {
  return {
    type: AUTH_LOGOUT_START,
    payload: {
      userId: payload.userId
    }
  };
};
export var logoutDone = function logoutDone(payload) {
  return {
    type: AUTH_LOGOUT_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var logoutFail = function logoutFail(payload) {
  return {
    type: AUTH_LOGOUT_FAIL,
    payload: payload
  };
};