import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse} from 'react-bootstrap';
import Search from "../SearchAndFilters/Search";
import Filters from "../SearchAndFilters/Filters/Filters";
import MapViewFilter from "../SearchAndFilters/MapViewFilter/MapViewFilter";


function SubHeader(props) {
    const [opened, setOpened] = useState(true);
    const {onStateToggle} = props;

    return (
        <>
            <Collapse in={opened} onEntered={(el) => onStateToggle && onStateToggle(true, el.clientHeight)}
                      onExited={() => onStateToggle && onStateToggle(false)}>
                <div className="w-100 p-3 navbar subheader">
                    <Filters isInSubheader={true}/>
                    <div className="col-md-3 pr-0 d-flex">
                        {props.showMapViewFilter && <MapViewFilter/>}
                        <Search suggestionsLimit={5} isInSubheader={true} geocoding={true}/>
                    </div>
                </div>
            </Collapse>

            <span
                id="collapse-filters-toggle"
                className={`w-100 subheader-toggle ${opened ? 'expanded' : ''}`}
                onClick={() => setOpened(!opened)}
                aria-controls="collapse-filters"
                aria-expanded={opened}
            />
        </>
    );
}

SubHeader.propTypes = {
    onStateToggle: PropTypes.func,
    showMapViewFilter: PropTypes.bool,
};
export default SubHeader;
