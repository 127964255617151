import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Map as CoreMap, MapControl } from "@mappr/react-lib";
import LayersMenu from "../../../components/Map/LayersMenu/LayersMenu";
import GeocoderPopup from "../../../components/GeocoderPopup/GeocoderPopup";
import StreetViewButton from "../../../components/Map/StreetViewButton/StreetViewButton";
import { AppContext } from "../../../context";
import PopUp from "../../../components/Map/PopUp/PopUp";
import { useHistory } from "react-router-dom";
import ModelToggleButton from "../../../components/Map/ModelToggleButton/ModelToggleButton";
import { getDomain } from "../../../utils/envUtils";
import SecondaryLayerPopUp from "../../../components/Map/SecondaryLayerPopUp/SecondaryLayerPopUp";

function MapPage(props) {
  const currentBaseMap = sessionStorage.getItem(`${getDomain()}-mpr-base-map`);
  const [currentBasemapInfo, setCurrentBasemapInfo] = useState();
  const {
    mapCenter,
    mapCenterMobile,
    mapZoom,
    mapZoomMobile,
    minZoom,
    minZoomMobile,
    maxZoom,
    maxZoomMobile,
    mapPitch, 
    mapPitchMobile, 
    minPitch,
    minPitchMobile,
    maxPitch,
    maxPitchMobile,
    boundingBox,
    isBboxIncluded,
    models,
    show3dSwitch,
  } = props.projectConfigs;
  const [mapReady, setMapReady] = useState(false);
  const history = useHistory();

  let containerClass = "col-12";
  if (props.position === "left") {
    containerClass = "col-8 order-1";
  } else if (props.position === "right") {
    containerClass = "col-8 order-2";
  }
  
  useEffect(() => {
    if (currentBaseMap) {
      const basemapInfo = props.basemaps.filter(
        (basemap) => basemap.name === currentBaseMap
      );
      setCurrentBasemapInfo(basemapInfo[0]);
    }
  }, [currentBaseMap]);

  const openStreetViewModal = (feature, methods) => {
    console.log(feature);
    let obj = feature.properties;
    methods.setMedias([{ embedUrl: obj.URL }]);

    return false;
  };

  const handleBasemapChange = () => {
    history.go(0);
  };

  return (
    <div
      className={`container-fluid h-100 w-100 sidebar px-0 position-absolute ${
        !props.active ? "page-hidden" : "page-active"
      }`}
    >
      {/*<div id="map-container" className={`bg-grey p-0 ${containerClass}`}>*/}
      <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-stretch align-content-stretch flex-nowrap">
        <AppContext.Consumer>
          {({ streetViewModal }) => {
            return (
              currentBasemapInfo && (
                <CoreMap
                  lng={mapCenterMobile?.longitude || mapCenter.longitude}
                  lat={mapCenterMobile?.latitude || mapCenter.latitude}
                  zoom={mapZoomMobile || mapZoom}
                  url={currentBasemapInfo && currentBasemapInfo.url}
                  models={models || []}
                  geocoderPopupComponent={GeocoderPopup}
                  openStreetViewModal={(feature) =>
                    openStreetViewModal(feature, {
                      setMedias: streetViewModal.setMedias,
                    })
                  }
                  setMapReady={(isReady) => setMapReady(isReady)}
                  isMobile={true}
                  PopUpComponent={PopUp}
                  SecondaryLayerPopUpComponent={SecondaryLayerPopUp}
                  history={history}
                  minZoom={minZoomMobile || minZoom}
                  maxZoom={maxZoomMobile || maxZoom}
                  pitch={mapPitchMobile || mapPitch || 45}
                  minPitch={minPitchMobile || minPitch || 0}
                  maxPitch={maxPitchMobile || maxPitch || 60}
                  boundingBox={boundingBox}
                  isBboxIncluded={props.projectConfigs?.isBboxIncluded !== false}
                  onBasemapChange={handleBasemapChange}
                />
              )
            );
          }}
        </AppContext.Consumer>
        <div
          style={{ bottom: "0", width: "100vw" }}
          id={"mobilePopupContainer"}
        ></div>

        {mapReady && (
          <MapControl position={"bottom-left"}>
            <LayersMenu buttonType="square" />
          </MapControl>
        )}

        {mapReady && show3dSwitch && (
          <MapControl position={"bottom-left"}>
            <ModelToggleButton />
          </MapControl>
        )}

        {mapReady && (
          <MapControl position={"bottom-left"}>
            <StreetViewButton />
          </MapControl>
        )}
      </div>
    </div>
  );
}

MapPage.propTypes = {
  position: PropTypes.oneOf(["left", "right", "full"]).isRequired,
  loading: PropTypes.bool,
};

MapPage.defaultProps = {
  position: "left",
  loading: true,
};

export default MapPage;
