import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {URLManager} from "@mappr/react-lib";
import ShareButtons from '../../ShareButtons';
import {useTranslation} from "react-i18next";

const ShareModal = ({open, onClose}) => {
    const {t} = useTranslation();
    const getEmbedCode = (url) => {
        return `<iframe width="100%" height="100%" src="${url}" style="display:block;height:100vh;width:100vw" frameborder="0" allow="map; encrypted-geolocation" allowfullscreen/>`;
    }

    const _handleCopy = () => {
        const textArea = document.getElementById('copy-area');
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            textArea.blur();
            if (window.getSelection) { // All browsers, except IE <=8
                window.getSelection().removeAllRanges();
            } else if (document.selection) { // IE <=8
                document.selection.empty();
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    };
    return (
        <Modal id="modal-share" show={open} centered={true} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>
                    {t('modal.share.title')}
                </Modal.Title>
                <Button className="close text-muted" onClick={onClose} data-dismiss="modal"
                        aria-label="Close">
                    <i className="icon icon-close"/>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <URLManager>
                    {({shortUrl, fullUrl, error, loading}) => (
                        <>
                            <ShareButtons shortUrl={shortUrl}/>
                            <p className="font-weight-bold mt-2 mb-0 line-height-2">{t('modal.share.embed')}</p>
                            <Row>
                                <Col md={10}>
                                         <textarea id="copy-area" className="code-area" cols="45" rows="5" readOnly
                                                   value={getEmbedCode(shortUrl)}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{span: 4, offset: 7}}>
                                    <Button variant="link"
                                            onClick={_handleCopy}>{t('modal.share.buttons.copy')}</Button>
                                </Col>
                            </Row>
                        </>
                    )
                    }
                </URLManager>
            </Modal.Body>

            {/*<Modal.Footer>*/}
            {/*<Button variant="link"*/}
            {/*        onClick={this.props.onClose}>{this.props.t('modal.share.buttons.close')}</Button>*/}
            {/*<Button variant="primary"*/}
            {/*        onClick={this._handleCopy}>{this.props.t('modal.share.buttons.copy')}</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    )
}

ShareModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
ShareModal.defaultProps = {
    open: false
};

export default ShareModal;
