import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import { watchAll } from './sagas';
var devMode = process.env.NODE_ENV !== 'production';
var sagaMiddleware = createSagaMiddleware();
var middleware = [].concat(getDefaultMiddleware({
  thunk: false
}), [sagaMiddleware]);
if (devMode) {
  // middleware.push(logger);
}
var store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
  devTools: devMode,
  preloadedState: {}
});
sagaMiddleware.run(watchAll);
export default store;