import React from 'react';
import withRender from '../../hoc/withRender';
import useAnalytics from '../../hooks/useAnalytics';
var Analytics = function Analytics(props) {
  var _useAnalytics = useAnalytics(),
    sendEvent = _useAnalytics.sendEvent,
    sendPageView = _useAnalytics.sendPageView;
  return props.render({
    sendEvent: sendEvent,
    sendPageView: sendPageView
  });
};
export default withRender(Analytics);