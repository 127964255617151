import * as actionTypes from '../actionTypes';

/**
 * The main action that is triggered when a new search query should be executed
 * @param text
 * @param {string} valueAction="add" One of ["add", "remove"]
 * @param masks
 * @returns {{type: string, payload: {dataSetId: *, text: *}}}
 */
export var search = function search(text, valueAction, masks) {
  if (valueAction === void 0) {
    valueAction = 'add';
  }
  return {
    type: actionTypes.SEARCH_START,
    payload: {
      text: text,
      valueAction: valueAction,
      masks: masks
    }
  };
};
export var searchDone = function searchDone(payload) {
  return {
    type: actionTypes.SEARCH_DONE,
    payload: payload
  };
};

/**
 * The main action that is triggered when a new search query should be executed
 * @param value
 * @returns {{type: string, payload: {dataSetId: *, text: *}}}
 */
export var suggest = function suggest(value) {
  return {
    type: actionTypes.SUGGESTION_START,
    payload: {
      text: value
    }
  };
};
export var suggestDone = function suggestDone(payload) {
  return {
    type: actionTypes.SUGGESTION_DONE,
    payload: payload
  };
};
export var addMasks = function addMasks(payload) {
  return {
    type: actionTypes.ADD_MASKS_DONE,
    payload: payload
  };
};