import React from 'react';
import {Button} from "react-bootstrap";
import './StreetViewButton.scss';
import {Layers} from '@mappr/react-lib';

function StreetViewButton(props) {
    return (
        <Layers group={"streetView"} type="multi">
            {({layers, activeLayers}, {toggleLayer}) => {
                if (!layers.length) {
                    return null;
                }
                return (<Button variant="info" className="btn-street-view d-inline-block btn-square" title="street view" active={activeLayers}
                                onClick={(ev) => toggleLayer(null, "streetView")}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z"/></svg>
                    Street view
                </Button>)
            }}
        </Layers>
    );
}

StreetViewButton.propTypes = {};
StreetViewButton.defaultProps = {};

export default StreetViewButton;
