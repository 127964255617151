import dateFormat from "dateformat";
import defaultImageUrl from "../assets/img/pattern.png";
import videoPlaceholder from "../assets/img/video_placeholder.jpg";

export function getImageURL(image, domain, size, url) {
  const path = image.filePath
    ? image.filePath.split("/" + domain + "/").join("")
    : null;

  return `${url}/projects/${domain}/image/${size}/${path}`;
}

export function toMediaInfo(image, domain, mprApiUrl) {
  if (!image) return {};

  return {
    original:
      typeof image === "string"
        ? image
        : getImageURL(image, domain, "1024x1024", mprApiUrl),
    thumbnail:
      typeof image === "string"
        ? image
        : getImageURL(image, domain, "256x256", mprApiUrl),
    metadata: {},
    // originalTitle: metaContainer.metadata.title,
    // fullDescription: metaContainer.metadata.description || "",
    // description: [
    //   metaContainer.metadata.author
    //     ? "by " + metaContainer.metadata.author
    //     : null,
    //   metaContainer.metadata.created
    //     ? dateFormat(metaContainer.metadata.created, "longDate")
    //     : null,
    // ]
    //   .filter((_) => !!_)
    //   .join(", "),
    // date: metaContainer.metadata.created,
  };
}

export function getYoutubeId(url) {
  const regExp =
    /^.*((youtu.be\/)|(youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  if (match) {
    if (match[8] && match[8].length === 11) return match[8];
    else if (match[7] && match[7].length === 11) return match[7];
    else return null;
  } else {
    return null;
  }
}

export function getVimeoId(url) {
  // eslint-disable-next-line no-useless-escape
  const regExp =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
  const match = url.match(regExp);
  return match && match.length ? match[1] : null;
}

export function toExternalMediaInfo(
  mediaItem,
  placeholder,
  { domain, mprApiUrl }
) {
  if (!mediaItem.image) {
    return {
      original: placeholder,
      thumbnail: placeholder,
      embedUrl: mediaItem.url,
    };
  }

  return {
    ...(toMediaInfo(mediaItem.image, domain, mprApiUrl) ||
      (placeholder && { original: placeholder, thumbnail: placeholder })),
    embedUrl: mediaItem.url,
  };
}
