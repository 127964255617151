function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { APP_GENERATE_SHORT_URL_START, APP_GENERATE_SHORT_URL_DONE, APP_GENERATE_SHORT_URL_FAIL } from '../actionTypes';
export var generateShortUrl = function generateShortUrl(url) {
  return {
    type: APP_GENERATE_SHORT_URL_START,
    payload: {
      url: url
    }
  };
};
export var generateShortUrlDone = function generateShortUrlDone(payload) {
  return {
    type: APP_GENERATE_SHORT_URL_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var generateShortUrlFail = function generateShortUrlFail(payload) {
  return {
    type: APP_GENERATE_SHORT_URL_FAIL,
    payload: payload
  };
};