import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ItemsInfo, ResultOrder } from "@mappr/react-lib";
import { AppContext } from "../../../../context";
import { Trans } from "react-i18next";
import ListOrderBy from "../ListOrderBy/ListOrderBy";

function ListHeader() {
  return (
    <AppContext.Consumer>
      {({ listViewType }) => {
        const isList = listViewType.value === "list";
        const isCard = listViewType.value === "card";

        return (
          <ItemsInfo>
            {({ loading, error, count }) => {
              if (loading || error) {
                return null;
              }
              return (
                <div className="ListHeaderContents d-flex w-100 px-0 py-2">
                  <div className="col-4 align-self-start">
                    <h5 className="mb-0 line-height-1-5 resultsCount">
                      <Trans i18nKey="results:number_short" count={count}>
                        <span className="text-primary">{{ count }}</span>
                        <small className="text-normal text-smaller">
                          results
                        </small>
                      </Trans>
                    </h5>
                  </div>

                  <div className="col-8 align-self-end text-right">
                      <ResultOrder>
                          {({order, orderOptions, showListOrder}, {changeOrder}) => {
                              return showListOrder && <ListOrderBy
                                  order={order}
                                  orderOptions={orderOptions}
                                  changeOrder={changeOrder}
                              />
                          }}
                      </ResultOrder>
                    <Button
                      variant="link"
                      className={isList ? "text-primary" : "text-muted"}
                      onClick={() => listViewType.handleChange("list")}
                      disabled={isList}
                    >
                      <i className="icon icon-list" />
                    </Button>
                    <Button
                      variant="link"
                      className={isCard ? "text-primary" : "text-muted"}
                      onClick={() => listViewType.handleChange("card")}
                      disabled={isCard}
                    >
                      <i className="icon icon-cards" />
                    </Button>
                  </div>
                </div>
              );
            }}
          </ItemsInfo>
        );
      }}
    </AppContext.Consumer>
  );
}

ListHeader.propTypes = {};

export default ListHeader;
