import withRender from '../../hoc/withRender';
import PropTypes from 'prop-types';
var Filters = function Filters(props) {
  var filters = props.filters,
    loading = props.loading,
    selectedValues = props.selectedValues,
    initialFilters = props.initialFilters,
    toggleFilter = props.toggleFilter,
    toggleFilterValues = props.toggleFilterValues,
    toggleRangeFilter = props.toggleRangeFilter,
    removeAllFilters = props.removeAllFilters;
  return props.render({
    loading: loading,
    filters: filters,
    selectedValues: selectedValues,
    initialFilters: initialFilters
  }, {
    onToggle: function onToggle(filterKey, value) {
      return toggleFilter(filterKey, value);
    },
    onToggleValues: function onToggleValues(filterKey, values) {
      return toggleFilterValues(filterKey, values);
    },
    removeAllFilters: removeAllFilters,
    onSelect: function onSelect(filterKey, start, end) {
      return toggleRangeFilter(filterKey, start, end);
    }
  });
};
Filters.propTypes = {
  children: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func,
  removeAllFilters: PropTypes.func,
  toggleRangeFilter: PropTypes.func,
  filters: PropTypes.array,
  initialFilters: PropTypes.array
};
export default withRender(Filters);