function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { SELECT_MAP_VIEW_FILTER } from '../../actionTypes';
import { ADD_SELECTED_VALUES_DONE, DATA_FILTERS_LOAD_DONE, DATA_FILTERS_LOAD_FAIL, DATA_FILTERS_LOAD_START, DATA_FILTERS_TOGGLE_LOAD_DONE, FILTER_TOGGLE_DONE, FILTERS_DESELECT_DONE, FILTERS_DESELECT_START } from '../../actionTypes/datasetSlice';
var initialState = {
  loading: true,
  filters: [],
  selectedValues: {},
  initialFilters: [],
  selectedMapViewFilter: ''
};
export default function filtersReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case DATA_FILTERS_LOAD_START:
      {
        return _extends({}, state, {
          loading: true
        });
      }
    case DATA_FILTERS_LOAD_DONE:
      {
        return _extends({}, state, {
          loading: false,
          error: null,
          filters: action.payload.filters,
          initialFilters: action.payload.filters
        });
      }
    case DATA_FILTERS_TOGGLE_LOAD_DONE:
      {
        return _extends({}, state, {
          loading: false,
          error: null,
          filters: action.payload.filters
        });
      }
    case DATA_FILTERS_LOAD_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          error: action.payload.error
        });
      }
    case FILTER_TOGGLE_DONE:
      {
        var _action$payload$selec;
        return _extends({}, state, {
          selectedValues: (_action$payload$selec = action.payload.selectedValues) !== null && _action$payload$selec !== void 0 ? _action$payload$selec : {},
          error: null
        });
      }
    case FILTERS_DESELECT_START:
      {
        return _extends({}, state, {
          loading: true,
          error: null
        });
      }
    case FILTERS_DESELECT_DONE:
      {
        return _extends({}, state, {
          loading: false,
          selectedValues: {},
          error: null
        });
      }
    case ADD_SELECTED_VALUES_DONE:
      {
        var _action$payload$selec2;
        return _extends({}, state, {
          selectedValues: (_action$payload$selec2 = action.payload.selectedValues) !== null && _action$payload$selec2 !== void 0 ? _action$payload$selec2 : {},
          error: null
        });
      }
    case SELECT_MAP_VIEW_FILTER:
      {
        var _action$payload;
        return _extends({}, state, {
          selectedMapViewFilter: (_action$payload = action.payload) !== null && _action$payload !== void 0 ? _action$payload : '',
          error: null
        });
      }
    default:
      return state;
  }
}