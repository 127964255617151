import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
import { useSelector } from 'react-redux';
var NavitiaSuggestions = function NavitiaSuggestions(props) {
  useEffect(function () {
    if (props.from && props.to) {
      onUpdate(props.from);
      onUpdate(props.to);
    }
  }, []);
  var onSelect = function onSelect(value) {
    props.onSelect(value);
  };
  return props.render({
    suggestions: props.suggestions,
    loading: props.loading
  }, {
    onSelect: onSelect
  });
};
NavitiaSuggestions.propTypes = {
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
  geocoderResults: PropTypes.array,
  children: PropTypes.func,
  loading: PropTypes.bool
};
export default withRender(NavitiaSuggestions);