import React from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DropdownToggle from "react-bootstrap/DropdownToggle";

const ListOrderBy = (props) => {
  const { t } = useTranslation();
  const { order, orderOptions, changeOrder } = props;

  return (
    <Dropdown className="d-inline-block">
      <DropdownToggle
        variant="link"
        data-display="static"
        className={props.buttonClasses}
      >
        {window.innerWidth > 1100 ? (
          t('project:orderBy.' + order.field)
        ) : (
          <i className="icon icon-arrow_down" />
        )}
      </DropdownToggle>
      <Dropdown.Menu style={{ top: "auto" }} className={props.menuClasses}>
        {orderOptions.map((order, index) => (
          <Dropdown.Item
            key={index}
            onClick={(ev) => {changeOrder(order)}}
          >
            {t('project:orderBy.' + order.field)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ListOrderBy.propTypes = {
  order: PropTypes.object.isRequired,
  orderOptions: PropTypes.array.isRequired,
  changeOrder: PropTypes.func.isRequired,
  menuClasses: PropTypes.string,
  buttonClasses: PropTypes.string,
};
ListOrderBy.defaultProps = {
  menuClasses: "",
  buttonClasses: "text-body text-smaller",
};

export default ListOrderBy;
