import React from 'react';
export var getHash = function getHash(key, json) {
  if (json === void 0) {
    json = true;
  }
  var hash = window.location.hash.replace("#", "");
  var params = new URLSearchParams(hash);
  if (key) return params.get(key) ? json ? JSON.parse(params.get(key)) : params.get(key) : null;else return params.keys();
};
export var setHash = function setHash(key, value) {
  var hash = window.location.hash.replace("#", "");
  var params = new URLSearchParams(hash);
  if (!value) params["delete"](key);else params.set(key, JSON.stringify(value));
  window.location.hash = params.toString();
};