function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { ADD_MASKS_DONE, SEARCH_DONE, SEARCH_FAIL, SEARCH_START, SUGGESTION_DONE, SUGGESTION_FAIL } from '../../actionTypes';
var initialState = {
  text: '',
  masks: [],
  error: null,
  suggestions: []
};
export default function searchReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case SEARCH_START:
      return _extends({}, state, {
        text: action.payload.text
      });
    case SEARCH_DONE:
      return _extends({}, state, {
        error: null,
        masks: action.payload.masks
      });
    case SEARCH_FAIL:
      return _extends({}, state, {
        error: action.payload.error
      });
    case SUGGESTION_DONE:
      return _extends({}, state, {
        suggestions: action.payload.suggestions
      });
    case SUGGESTION_FAIL:
      return _extends({}, state, {
        suggestions: []
      });
    case ADD_MASKS_DONE:
      return _extends({}, state, {
        masks: action.payload.masks
      });
    default:
      return state;
  }
}
;