import React, { useState, useRef, useContext } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context";

function ShareButtons(props) {
  const [showMessage, setShowMessage] = useState(false);
  const messageTarget = useRef(null);
  const { projectConfigs } = useContext(AppContext);
  const { socialShareButtons } = projectConfigs;
  const { t } = useTranslation();

  function getShareableURL() {
    return props.shortUrl;
  }

  function copyToClipboard() {
    let str = getShareableURL();
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    setShowMessage(true);
    setTimeout(function () {
      setShowMessage(false);
    }, 750);
  }

  function getMailToURL() {
    return `mailto:?body=${encodeURIComponent(
      `${t("modal.share.shareText")} ${getShareableURL()}`
    )}`;
  }

  function getEncodedSharableURL() {
    return encodeURIComponent(getShareableURL());
  }

  function getTWIntentURL() {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      t("modal.share.shareText")
    )}&url=${getEncodedSharableURL()}`;
  }

  function getLInShareUrl() {
    return `http://www.linkedin.com/shareArticle?mini=true&url=${getEncodedSharableURL()}&source=Mappr&title=${t("modal.share.shareText")}&summary=${encodeURIComponent(
      `${t("modal.share.shareText")}  ${getEncodedSharableURL()}`
    )}`;
  }

  const handleFBShare = () => {
    if (window.FB) {
      window.FB.ui(
        {
          method: "share",
          href: getShareableURL(),
        },
        function (response) {}
      );
    }
  };

  const handleINShare = () => {
    window.open(getLInShareUrl(), "", "width=550,height=500");
  };

  return (
    <div>
      {socialShareButtons?.length > 0 && (
        <div className="mb-2">
          {socialShareButtons.includes("email") && (
            <a href={getMailToURL()}>
              <Button variant="primary" className="btn-round-sm mr-3">
                <i className="icon icon-email" />
              </Button>
            </a>
          )}
          {socialShareButtons.includes("facebook") && (
            <Button
              variant="primary"
              className="btn-round-sm mr-3"
              onClick={handleFBShare}
            >
              <i className="icon icon-facebook" />
            </Button>
          )}
          {socialShareButtons.includes("linkedin") && (
            <Button
              variant="primary"
              className="btn-round-sm mr-3"
              onClick={handleINShare}
            >
              <i className="icon icon-linkedin_2" />
            </Button>
          )}
          {socialShareButtons.includes("twitter") && (
            <a href={getTWIntentURL()}>
              <Button variant="primary" className="btn-round-sm mr-3">
                <i className="icon icon-twitter" />
              </Button>
            </a>
          )}
        </div>
      )}
      <span className="mr-2 url-container">{props.shortUrl}</span>
      <Button
        ref={messageTarget}
        variant="link"
        style={{ fontSize: "15px" }}
        onClick={copyToClipboard}
      >
        {t("modal.share.buttons.copy")}
      </Button>
      {!props.notooltip ? (
        <Overlay
          target={messageTarget.current}
          show={showMessage}
          placement="right"
        >
          {({ show, ...overlayProps }) => (
            <Tooltip id={`copy-message`} {...overlayProps}>
              {t("modal.share.copy_message")}
            </Tooltip>
          )}
        </Overlay>
      ) : null}
    </div>
  );
}

export default ShareButtons;
