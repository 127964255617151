import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import AppInfoPage from "./AppInfoPage/AppInfoPage";
import PageIndicators from "../../PageIndicators/PageIndicators";
import "./AppInfoModal.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function AppInfoModal(props) {
  const { domain } = props.project.configs;
  const { t } = useTranslation();
  const { lang } = useParams();
  const [page, setPage] = useState(0);
  const appInfoModal = props.appInfoModal[lang];

  function resetState() {
    setPage(0);
  }

  return (
    <Modal
      id="modal-app-info"
      className="AppInfoModal"
      show={props.open}
      centered={true}
      scrollable={true}
      onHide={props.onClose}
      onExited={resetState}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title />
        <Button
          className="close text-muted"
          onClick={props.onClose}
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="icon icon-close" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {appInfoModal &&
          appInfoModal.map((info, index) => (
            <AppInfoPage
              key={`page-${index}`}
              shown={page === index}
              title={info.title}
              //  image={`${mprApiUrl}/${domain}/app-info/${info.image}`}
              image={`https://cloud.squidex.io/api/assets/${domain}/${info.image}`} //TODO: change to api url when image is saved during import
              description={info.description}
            />
          ))}
      </Modal.Body>
      <Modal.Footer className="justify-content-center flex-column">
        <div>
          <PageIndicators
            count={appInfoModal && appInfoModal.length}
            activeIndex={page}
            onSelect={(index, ev) => setPage(index)}
          />
        </div>
        <div>
          {appInfoModal && appInfoModal.length > 1 ? (
            <Button
              className="mb-2"
              variant="primary"
              onClick={() => setPage(page + 1)}
            >
              {t("appInfo.buttons.next")}
            </Button>
          ) : (
            <Button className="mb-2" variant="primary" onClick={props.onClose}>
              {t("appInfo.buttons.goToMap")}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

AppInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
AppInfoModal.defaultProps = {
  open: false,
};

export default AppInfoModal;
