function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DATA_ITEMS_LOAD_DONE, DATA_ITEMS_LOAD_FAIL, DATA_ITEMS_LOAD_START, DATA_ITEM_LOAD_DONE, DATA_ITEM_LOAD_FAIL, DATA_ITEM_LOAD_START, DATA_ITEM_DESELECT_START, DATA_ITEM_DESELECT_DONE, DATA_FILTERS_LOAD_START, DATA_FILTERS_LOAD_DONE, DATA_FILTERS_LOAD_FAIL, FILTER_TOGGLE_START, FILTER_TOGGLE_DONE, DATA_FILTERS_TOGGLE_LOAD_DONE, DATA_ITEM_HOVER_DONE, DATA_ITEM_HOVER_OFF, FILTERS_DESELECT_START, FILTERS_DESELECT_DONE, FILTER_VALUES_TOGGLE_START, DATA_ITEMS_ORDER_SET } from '../actionTypes/datasetSlice';
import * as actionTypes from '../actionTypes';
export var dataItemsLoad = function dataItemsLoad(payload) {
  return {
    type: DATA_ITEMS_LOAD_START,
    payload: payload || {}
  };
};
export var dataItemsLoadDone = function dataItemsLoadDone(payload) {
  return {
    type: DATA_ITEMS_LOAD_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var dataItemsLoadFail = function dataItemsLoadFail(payload) {
  return {
    type: DATA_ITEMS_LOAD_FAIL,
    payload: payload
  };
};
export var dataItemLoad = function dataItemLoad(payload) {
  return {
    type: DATA_ITEM_LOAD_START,
    payload: payload || {}
  };
};
export var dataItemLoadDone = function dataItemLoadDone(payload) {
  return {
    type: DATA_ITEM_LOAD_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var dataItemLoadFail = function dataItemLoadFail(payload) {
  return {
    type: DATA_ITEM_LOAD_FAIL,
    payload: payload
  };
};
export var dataItemDeselect = function dataItemDeselect() {
  return {
    type: DATA_ITEM_DESELECT_START
  };
};
export var dataItemDeselectDone = function dataItemDeselectDone() {
  return {
    type: DATA_ITEM_DESELECT_DONE
  };
};
export var dataFiltersLoad = function dataFiltersLoad(payload) {
  return {
    type: DATA_FILTERS_LOAD_START,
    payload: payload || {}
  };
};
export var dataFiltersLoadDone = function dataFiltersLoadDone(payload) {
  return {
    type: DATA_FILTERS_LOAD_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var dataFiltersToggleLoadDone = function dataFiltersToggleLoadDone(payload) {
  return {
    type: DATA_FILTERS_TOGGLE_LOAD_DONE,
    payload: _extends({}, payload, {
      error: null
    })
  };
};
export var dataFiltersLoadFail = function dataFiltersLoadFail(payload) {
  return {
    type: DATA_FILTERS_LOAD_FAIL,
    payload: payload
  };
};
export var toggleFilter = function toggleFilter(filterKey, value) {
  return {
    type: FILTER_TOGGLE_START,
    payload: {
      filterKey: filterKey,
      value: value
    }
  };
};
export var toggleFilterValues = function toggleFilterValues(filterKey, values, isRange) {
  return {
    type: FILTER_VALUES_TOGGLE_START,
    payload: {
      filterKey: filterKey,
      isRange: isRange,
      values: values
    }
  };
};
export var toggleFilterDone = function toggleFilterDone(selectedValues) {
  return {
    type: FILTER_TOGGLE_DONE,
    payload: {
      selectedValues: selectedValues,
      isToggle: true
    }
  };
};
export var deselectAllFilters = function deselectAllFilters() {
  return {
    type: FILTERS_DESELECT_START
  };
};
export var deselectAllFiltersDone = function deselectAllFiltersDone() {
  return {
    type: FILTERS_DESELECT_DONE
  };
};
export var addSelectedValues = function addSelectedValues(payload) {
  return {
    type: actionTypes.ADD_SELECTED_VALUES_DONE,
    payload: payload
  };
};
export var dataItemHover = function dataItemHover(payload) {
  return {
    type: actionTypes.DATA_ITEM_HOVER,
    payload: payload
  };
};
export var dataItemHoverDone = function dataItemHoverDone(payload) {
  return {
    type: DATA_ITEM_HOVER_DONE,
    payload: payload
  };
};
export var dataItemHoverOff = function dataItemHoverOff(payload) {
  return {
    type: DATA_ITEM_HOVER_OFF
  };
};
export var dataSetOrder = function dataSetOrder(payload) {
  return {
    type: DATA_ITEMS_ORDER_SET,
    payload: payload
  };
};