import React from "react";
import PropTypes from "prop-types";
import POIGalleryButton from "./POIGalleryButton";

import placeholder from "../../../assets/img/pattern.png";
import videoPlaceholder from "../../../assets/img/video_placeholder.jpg";
import pdfPlaceholder from "../../../assets/img/pdf_placeholder.png";
import {
  getVimeoId,
  getYoutubeId,
  toExternalMediaInfo,
  toMediaInfo,
} from "../../../utils/mediaUtils";
import { useContext } from "react";
import { AppContext } from "../../../context";

function POIGalleries({
  mainImage,
  images = [],
  medias = [],
  pdfs,
  includeMainImage,
  includeMediaThumbnails,
  inColumn,
  domain,
}) {
  const { mprApiUrl } = useContext(AppContext);
  const galleryImages = images.map((_) => toMediaInfo(_, domain, mprApiUrl)); //TODO: add metadata

  if (includeMainImage && mainImage) {
    galleryImages.push(toMediaInfo(mainImage, domain, mprApiUrl));
  }
  if (includeMediaThumbnails) {
    galleryImages.concat(medias.map((_) => _.image));
  }

  const videos = medias
    ? medias
        .filter((_) => _.type === "video")
        .map((video) => {
          let mediaInfo = toExternalMediaInfo(video, placeholder, {domain, mprApiUrl});

          let videoId;
          if ((videoId = getYoutubeId(video.url))) {
            return {
              original:
                mediaInfo.original ||
                `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
              thumbnail:
                mediaInfo.thumbnail ||
                `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
              embedUrl: `https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0`,
            };
          } else if ((videoId = getVimeoId(video.url))) {
            return {
              original: mediaInfo.original || videoPlaceholder,
              thumbnail: mediaInfo.thumbnail || videoPlaceholder,
              embedUrl: `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`,
            };
          } else {
            return {
              original: mediaInfo.original || videoPlaceholder,
              thumbnail: mediaInfo.thumbnail || videoPlaceholder,
              embedUrl: video.url,
            };
          }
        })
    : [];

  const streetViews = medias
    ? medias
        .filter((_) => _.type === "street_view")
        .map((_) => toExternalMediaInfo(_, placeholder, {domain, mprApiUrl}))
    : [];
  const threeDs = medias
    ? medias.filter((_) => _.type === "3d").map((_) => toExternalMediaInfo(_, undefined, {domain, mprApiUrl}))
    : [];
  const webLinks = medias
    ? medias.filter((_) => _.type === "web").map((_) => toExternalMediaInfo(_, undefined, {domain, mprApiUrl}))
    : [];
  const galleryPDFs = pdfs
    ? pdfs.map((pdf) => ({ ...pdf, ...toMediaInfo(pdfPlaceholder, domain, mprApiUrl) }))
    : [];
  const pdfMedias = medias
    ? medias
        .filter((_) => _.type === "pdf")
        .map((_) => ({ ..._, ...toMediaInfo(pdfPlaceholder, domain, mprApiUrl) }))
    : [];
  const combinedPDFs = galleryPDFs.concat(pdfMedias);
  return (
    <div
      className={`row galleries px-3 bg-white pb-5 ${
        inColumn ? "col col-md-8 m-auto" : ""
      }`}
    >
      {!!galleryImages.length && (
        <POIGalleryButton
          mediaType="photo"
          medias={galleryImages}
          thumbnail={galleryImages[0]}
        />
      )}
      {!!combinedPDFs.length && (
        <POIGalleryButton
          mediaType="pdf"
          medias={combinedPDFs}
          thumbnail={{ original: pdfPlaceholder }}
        />
      )}
      {!!videos.length && (
        <POIGalleryButton
          mediaType="video"
          medias={videos}
          thumbnail={videos[0]}
        />
      )}
      {!!threeDs.length && (
        <POIGalleryButton
          mediaType="3d"
          medias={threeDs}
          thumbnail={threeDs[0]}
        />
      )}
      {!!streetViews.length && (
        <POIGalleryButton
          mediaType="street_view"
          medias={streetViews}
          thumbnail={streetViews[0]}
        />
      )}
      {!!webLinks.length && (
        <POIGalleryButton
          mediaType="web"
          medias={webLinks}
          thumbnail={webLinks[0]}
        />
      )}
    </div>
  );
}

POIGalleries.propTypes = {
    domain: PropTypes.string.isRequired,
    images: PropTypes.array,
    medias: PropTypes.array,
    pdfs: PropTypes.array,
    includeMainImage: PropTypes.bool,
    includeMediaThumbnails: PropTypes.bool,
    inColumn: PropTypes.bool,
};

export default POIGalleries;
