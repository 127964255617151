import React from 'react';
import {AppContext} from '../../../context';
import Button from 'react-bootstrap/Button';
import './StreetViewModal.scss';
import {useTranslation} from "react-i18next";

function StreetViewModal({open, streetView = {}, sidebarStyle, withSidebar = true, onClose, sidebarCollapse}) {
    const {t} = useTranslation();

    if (!streetView || !open) {
        return null;
    }
    const LAYOUTS = {
        sidebarLeft: 1,
        sidebarRight: 2,
        headerLeft: 3,
        headerRight: 4,
        horizontal: 5,
    };
    return (
        <AppContext.Consumer>
            {({layout}) => {
                const position = layout === LAYOUTS.sidebarLeft || layout === LAYOUTS.headerLeft ? 'right' : (layout === LAYOUTS.sidebarRight || layout === LAYOUTS.headerRight ? 'left' : 'full');

                let containerClass = `order-1 col-sm-12 col-md-${sidebarCollapse ? "12" : "8"} `;
                if (position === 'right') {
                    containerClass = `order-2 col-sm-12 col-md-${sidebarCollapse ? "12" : "8"}`;
                }

                return (
                    <div className="StreetViewModal container-fluid h-100 position-absolute"
                         style={{
                             top: 0,
                             zIndex: 1031,
                             paddingTop: layout === LAYOUTS.headerLeft || layout === LAYOUTS.headerRight ? '66px' : '60px'
                         }}>
                        <section className="row justify-content-center h-100">
                            <div id="street-view-container" className={`p-0 ${containerClass}`}>
                                <div className="street-view-wrapper d-flex w-100 h-100">
                                    <iframe
                                        title={streetView.embedUrl}
                                        width="100%"
                                        height="auto"
                                        src={streetView.embedUrl}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                </div>
                                <Button variant="link" className="text-white position-absolute" style={{
                                    top: 0,
                                    right: 0
                                }} onClick={onClose}>
                                    <i className="icon icon-close"/>
                                </Button>
                            </div>
                            {withSidebar && !sidebarCollapse && (
                                <aside
                                    className={`col-4 px-0 h-100 ${position === 'right' ? 'order-1 border-right text-right' : 'order-2 border-left text-left'}`}
                                    style={sidebarStyle} onClick={onClose}>
                                    <Button variant="light" className="btn-street-view btn-back btn-sm mr-3 ml-3"
                                            onClick={onClose}>
                                        <i className="icon icon-back_arrow mr-2"/>
                                        {t('modal.streetView.buttons.backToMap')}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#666062"
                                             width="24px" height="24px">
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                            <path
                                                d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"/>
                                        </svg>
                                    </Button>
                                </aside>
                            )
                            }
                        </section>
                    </div>
                );
            }}

        </AppContext.Consumer>

    );
}

StreetViewModal.propTypes = {};
StreetViewModal.defaultProps = {
    onClose: () => {
    }
};

export default StreetViewModal;
