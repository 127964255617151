function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { GEOCODER_SEARCH_START, GEOCODER_SEARCH_DONE, GEOCODER_SEARCH_FAIL, GEOCODER_SELECT_START, GEOCODER_SELECT_FAIL, GEOCODER_SELECT_DONE, GEOCODER_CLEAR_DONE, GEOCODER_POIS_SEARCH_DONE } from '../../actionTypes';
var initialState = {
  list: [],
  poisList: [],
  item: null,
  error: null,
  loading: false
};
export var geocoderReducer = function geocoderReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case GEOCODER_SEARCH_START:
    case GEOCODER_SELECT_START:
      return _extends({}, state, {
        error: null,
        loading: true
      });
    case GEOCODER_SEARCH_DONE:
      return _extends({}, state, {
        list: [].concat(action.payload),
        error: null,
        loading: false
      });
    case GEOCODER_POIS_SEARCH_DONE:
      return _extends({}, state, {
        poisList: [].concat(action.payload),
        error: null,
        loading: false
      });
    case GEOCODER_SELECT_DONE:
      return _extends({}, state, {
        list: [],
        poisList: [],
        item: _extends({}, action.payload),
        error: null,
        loading: false
      });
    case GEOCODER_SEARCH_FAIL:
    case GEOCODER_SELECT_FAIL:
      return _extends({}, state, {
        list: [],
        poisList: [],
        item: null,
        error: action.payload.error,
        loading: false
      });
    case GEOCODER_CLEAR_DONE:
      return _extends({}, initialState);
    default:
      return state;
  }
};