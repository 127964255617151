function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DATA_ITEM_LOAD_DONE, DATA_ITEM_LOAD_FAIL, DATA_ITEM_LOAD_START, DATA_ITEM_DESELECT_START, DATA_ITEM_DESELECT_DONE } from '../../actionTypes/datasetSlice';
var initialState = {
  loading: false,
  error: null,
  item: null
};
export default function itemReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }
  switch (action.type) {
    case DATA_ITEM_LOAD_START:
      {
        return _extends({}, state, {
          loading: true
        });
      }
    case DATA_ITEM_LOAD_DONE:
      {
        return _extends({}, state, {
          loading: false,
          error: null,
          item: action.payload.item
        });
      }
    case DATA_ITEM_LOAD_FAIL:
      {
        return _extends({}, state, {
          loading: false,
          error: action.payload.error
        });
      }
    case DATA_ITEM_DESELECT_START:
      {
        return _extends({}, state, {
          loading: true,
          error: null
        });
      }
    case DATA_ITEM_DESELECT_DONE:
      {
        return _extends({}, state, {
          loading: false,
          error: null,
          item: null
        });
      }
    default:
      return state;
  }
}