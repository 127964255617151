// import { DATA_ITEMS_LOAD_START } from '../actionTypes';

import { CHANGE_MAP_BBOX, MAP_DRAW, SELECT_MAP_VIEW_FILTER, TOGGLE_LAYER_DONE, TOGGLE_LAYER_START } from '../actionTypes';
export var changeMapBbox = function changeMapBbox(payload) {
  return {
    type: CHANGE_MAP_BBOX,
    payload: payload || {}
  };
};
export var toggleLayer = function toggleLayer(payload) {
  return {
    type: TOGGLE_LAYER_START,
    payload: payload || {}
  };
};
export var toggleLayerDone = function toggleLayerDone(payload) {
  return {
    type: TOGGLE_LAYER_DONE,
    payload: payload || {}
  };
};
export var selectMapViewFilter = function selectMapViewFilter(payload) {
  return {
    type: SELECT_MAP_VIEW_FILTER,
    payload: payload || {}
  };
};
export var mapDraw = function mapDraw(payload) {
  return {
    type: MAP_DRAW,
    payload: payload || {}
  };
};