import React from 'react';
function ListItemLoader (props) {
  return (
    <li className="list-group-item media list-group-item-action d-sm-block d-lg-flex">
      <div className="placeholder animated placeholder-square rounded mr-2"/>
      <div className="media-body d-inline-block w-75">
        <div className="mt-0 mb-1">
          <i className="icon icon-location_pin mr-1 placeholder animated" style={{ fontSize: '1.5rem' }}/>
          <div className="placeholder placeholder-line-short animated rounded d-inline-block"/>
        </div>
        <div className="placeholder placeholder-line animated rounded"/>
      </div>
    </li>
  );
}

ListItemLoader.propTypes = {};
ListItemLoader.defaultProps = {};

export default ListItemLoader;
