export var SEARCH_START = 'search/start';
export var SEARCH_DONE = 'search/done';
export var SEARCH_FAIL = 'search/fail';
export var SUGGESTION_START = 'suggestion/start';
export var SUGGESTION_DONE = 'suggestion/done';
export var SUGGESTION_FAIL = 'suggestion/fail';
export var ADD_MASKS_DONE = 'ADD_MASKS_DONE';
export var NAVITIA_SEARCH_START = 'navitia/search/start';
export var NAVITIA_SEARCH_DONE = 'navitia/search/done';
export var NAVITIA_SEARCH_FAIL = 'navitia/search/fail';
export var NAVITIA_SUGGESTION_START = 'navitia/suggestion/start';
export var NAVITIA_SUGGESTION_DONE = 'navitia/suggestion/done';
export var NAVITIA_SUGGESTION_FAIL = 'navitia/suggestion/fail';
export var DESELECT_JOURNEY_START = 'deselect/journey/start';
export var DESELECT_JOURNEY_DONE = 'deselect/journey/done';