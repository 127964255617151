import { useEffect, useState } from 'react';
import { mapInstance } from '../mappr';

/**
 * This hook is used to provide access to the mapboxjs map instance that can be used to directly manipiulate the map.
 * @returns {Object | undefined}
 */
var useMap = function useMap() {
  var _useState = useState(1),
    v = _useState[0],
    setV = _useState[1];
  useEffect(function () {
    setV(v + 1);
  }, [mapInstance]);
  return {
    map: mapInstance
  };
};
export default useMap;