import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FilterItem from "./FilterItem";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../../../../context";

function FilterItems(props) {
  const {
    filterId,
    setShow,
    stats,
    onToggle,
    withIcons,
    lang,
    selectedValues,
    filters,
    closeOnSelect,
    groupSelectedFilterItems,
  } = props;
  const isMobile =
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1;

  const [sortedFilterValues, setSortedFilterValues] = useState([]);

  useEffect(() => {
    const filter = filters?.filter((filter) => filter.name === filterId).pop();
    const availableValues = filter && filter?.stats?.map((item) => item.value);

    if (groupSelectedFilterItems) {
      const selectedItems = stats.filter((item) =>
        selectedValues?.[filterId]?.includes(item.value)
      );
      const availableItems = stats.filter(
        (item) =>
          availableValues?.includes(item.value) &&
          !selectedValues?.[filterId]?.includes(item.value)
      );
      const disabledItems = stats.filter(
        (item) =>
          !selectedValues?.[filterId]?.includes(item.value) &&
          !availableValues?.includes(item.value)
      );

      setSortedFilterValues([
        ...selectedItems,
        ...availableItems,
        ...disabledItems,
      ]);
    } else {
      setSortedFilterValues(stats);
    }

  }, [stats, filters, selectedValues, filterId]);

  const dropdownMenuContents = (
    <AppContext.Consumer>
      {({ layout }) => {
        return (
          <Dropdown.Menu
            as="ul"
            className={
              "filter-dropdown list-group list-group-flush py-0 scrollable " +
              (layout === 2 && !isMobile ? "dropdown-menu-right" : "")
            }
            flip={props.flipDropdown}
          >
            {sortedFilterValues.map((filterItem, index) => {
              return (
                <FilterItem
                  key={`filter-${filterId}-${index}`}
                  filterId={filterId}
                  item={filterItem}
                  withIcon={withIcons}
                  onToggle={onToggle}
                  setShow={setShow}
                  lang={lang}
                  selectedValues={selectedValues}
                  filters={filters}
                  closeOnSelect={closeOnSelect}
                />
              );
            })}
          </Dropdown.Menu>
        );
      }}
    </AppContext.Consumer>
  );

  if (props.containerId) {
    let container = document.getElementById(props.containerId);
    if (!container) {
      container = document.createElement("div");
      container.id = props.containerId;
      container.style.position = "absolute";
      container.style.left = 0;
      container.style.top = 0;
      container.style.zIndex = "9999";
      container.style.transform = "translate3D(0px,32px,0px)";
      document.body.appendChild(container);
    }

    return ReactDOM.createPortal(dropdownMenuContents, container);
  } else {
    return dropdownMenuContents;
  }
}

FilterItems.propTypes = {
  containerId: PropTypes.string,
  flipDropdown: PropTypes.bool,
};
FilterItems.defaultProps = {
  flipDropdown: false,
};

export default FilterItems;
