import React, { Component, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
import ReactDOM from 'react-dom';
var SearchBox = function SearchBox(props) {
  var onSubmit = function onSubmit(value) {
    props.onSubmit(value);
    if (props.geocoding) {
      props.onGeocoderClear();
    }
  };
  var onUpdate = function onUpdate(value) {
    if (props.geocoding) {
      props.onGeocodeSearch(value, props.map);
    }
    props.onUpdate(value);
  };
  var onRemove = function onRemove(value) {
    props.onRemove(value);
  };
  return props.render({
    searchText: props.text,
    searchMasks: props.masks
  }, {
    onSearch: onSubmit,
    onUpdate: onUpdate,
    onRemove: onRemove
  });
};
SearchBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onGeocodeSearch: PropTypes.func.isRequired,
  text: PropTypes.string,
  masks: PropTypes.array,
  geocoding: PropTypes.bool,
  children: PropTypes.func
};
SearchBox.defaultProps = {
  geocoding: true,
  text: '',
  masks: []
};
export default withRender(SearchBox);