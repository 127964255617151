import { useDispatch, useSelector } from 'react-redux';
import withRender from '../../hoc/withRender';
import { generateShortUrl as _generateShortUrl } from '../../store/actions/urlShortener';
function URLManager(props) {
  var _useSelector = useSelector(function (state) {
      return state.urlShortener;
    }),
    url = _useSelector.url,
    error = _useSelector.error,
    loading = _useSelector.loading;
  var dispatch = useDispatch();
  return props.render({
    shortUrl: url,
    fullUrl: window.location.href,
    error: error,
    loading: loading
  }, {
    generateShortUrl: function generateShortUrl() {
      return dispatch(_generateShortUrl(window.location.href));
    }
  });
}
URLManager.propTypes = {};
export default withRender(URLManager);