import { GEOCODER_SEARCH_START, GEOCODER_SEARCH_DONE, GEOCODER_SEARCH_FAIL, GEOCODER_SELECT_START, GEOCODER_SELECT_DONE, GEOCODER_SELECT_FAIL, GEOCODER_CLEAR_START, GEOCODER_CLEAR_DONE, GEOCODER_POIS_SEARCH_DONE } from '../actionTypes';

/**
 * The main action that is fired when a geocoding search is initiated
 * @param text
 * @returns {{type: string, payload: {text: string}}}
 */
export var geocoderSearch = function geocoderSearch(text) {
  return {
    type: GEOCODER_SEARCH_START,
    payload: {
      text: text
    }
  };
};
export var geocoderSearchDone = function geocoderSearchDone(payload) {
  return {
    type: GEOCODER_SEARCH_DONE,
    payload: payload
  };
};
export var geocoderPOIsSearchDone = function geocoderPOIsSearchDone(payload) {
  return {
    type: GEOCODER_POIS_SEARCH_DONE,
    payload: payload
  };
};
export var geocoderSearchFail = function geocoderSearchFail(e) {
  return {
    type: GEOCODER_SEARCH_FAIL,
    payload: {
      error: e
    }
  };
};

/**
 * The main action that is fired when a geocoding list item is selected
 * @param value
 * @returns {{type: string, payload: {value: string}}}
 */
export var geocoderSelect = function geocoderSelect(value) {
  return {
    type: GEOCODER_SELECT_START,
    payload: {
      value: value
    }
  };
};
export var geocoderSelectDone = function geocoderSelectDone(payload) {
  return {
    type: GEOCODER_SELECT_DONE,
    payload: payload
  };
};
export var geocoderSelectFail = function geocoderSelectFail(e) {
  return {
    type: GEOCODER_SELECT_FAIL,
    payload: {
      error: e
    }
  };
};

/**
 * The main action that is fired when the geocoder data should be cleared
 * @returns {{type: string, payload: {}}}
 */
export var geocoderClear = function geocoderClear() {
  return {
    type: GEOCODER_CLEAR_START,
    payload: {}
  };
};
export var geocoderClearDone = function geocoderClearDone() {
  return {
    type: GEOCODER_CLEAR_DONE,
    payload: {}
  };
};