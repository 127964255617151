import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
var mapInstance;
var configure = function configure(options) {
  if (options.map) {
    mapInstance = options.map;
    mapInstance.getGeocoder = function () {
      return mapInstance._controls.find(function (control) {
        return control instanceof MapboxGeocoder;
      });
    };
  } else {
    console.warn('Mappr API is already initialised!');
  }
  return;
};
export default {
  configure: configure
};
export { mapInstance };