function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import React from 'react';
import PropTypes from 'prop-types';
import withRender from '../../hoc/withRender';
function ItemsInfo(props) {
  var loading = props.loading,
    error = props.error,
    info = props.info;
  return props.render(_extends({
    loading: loading,
    error: error
  }, info));
}
ItemsInfo.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  info: PropTypes.object
};
export default withRender(ItemsInfo);