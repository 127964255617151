export var DATA_ITEMS_LOAD_START = 'data/items/loadStart';
export var DATA_ITEMS_LOAD_DONE = 'data/items/loadDone';
export var DATA_ITEMS_LOAD_FAIL = 'data/items/loadFail';
export var DATA_ITEMS_ORDER_SET = 'data/items/setOrderStart';
export var DATA_FILTERS_LOAD_START = 'data/filters/loadStart';
export var DATA_FILTERS_LOAD_DONE = 'data/filters/loadDone';
export var DATA_FILTERS_LOAD_FAIL = 'data/filters/loadFail';
export var DATA_FILTERS_TOGGLE_LOAD_DONE = 'data/filters/toggleLoadDone';
export var ADD_SELECTED_VALUES_DONE = 'data/filters/addSelectedValuesDone';
export var DATA_ITEM_LOAD_START = 'data/item/loadStart';
export var DATA_ITEM_LOAD_DONE = 'data/item/loadDone';
export var DATA_ITEM_LOAD_FAIL = 'data/item/loadFail';
export var DATA_ITEM_DESELECT_START = 'data/item/deselectStart';
export var DATA_ITEM_DESELECT_DONE = 'data/item/deselectDone';
export var DATA_ITEM_DESELECT_FAIL = 'data/item/deselectFail';
export var DATA_INDEX_LOAD_START = 'data/index/loadStart';
export var DATA_INDEX_LOAD_DONE = 'data/index/loadDone';
export var DATA_INDEX_LOAD_FAIL = 'data/index/loadFail';
export var DATA_SEARCH_START = 'data/search/loadStart';
export var DATA_SEARCH_DONE = 'data/search/loadDone';
export var DATA_SEARCH_FAIL = 'data/search/loadFail';
export var FILTER_TOGGLE_START = 'data/filters/toggleFilterStart';
export var FILTER_VALUES_TOGGLE_START = 'data/filters/toggleFilterValuesStart';
export var FILTER_TOGGLE_DONE = 'data/filters/toggleFilterDone';
export var FILTERS_DESELECT_START = 'data/filters/deselectFiltersStart';
export var FILTERS_DESELECT_DONE = 'data/filters/deselectFiltersDone';
export var DATA_ITEM_HOVER = 'data/item/hoverStart';
export var DATA_ITEM_HOVER_DONE = 'data/item/hoverDone';
export var DATA_ITEM_HOVER_OFF = 'data/item/hoverOff';