import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './app/appSlice';
import authReducer from './auth/authSlice';
import datasetReducer from './dataset';
import { urlShortenerReducer } from './urlShortener/urlShortener';
import { geocoderReducer } from './dataset/geocoder';
export default combineReducers({
  app: appReducer,
  auth: authReducer,
  dataset: datasetReducer,
  urlShortener: urlShortenerReducer,
  geocoder: geocoderReducer
});