import ReactGA from "react-ga4";
import MatomoTracker from '@datapunt/matomo-tracker-js';
import GoogleTagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useRef } from 'react';
var useAnalytics = function useAnalytics() {
  var appState = useSelector(function (state) {
    return state.app;
  });
  var tracker = useRef();
  var project = appState.project;
  useEffect(function () {
    if (project) {
      var id = project.configs.analyticsId,
        type = project.configs.analytics,
        domain = project.configs.domain;
      if (type === 'google' && (!tracker.current || tracker.current instanceof MatomoTracker)) {
        console.info('Analytics: Set up Google Analytics');
        tracker.current = ReactGA;
        tracker.current.initialize(id, {
          debug: false
        });
        tracker.current.ga('set', 'anonymizeIp', true);
      } else if (type === 'matomo' && (!tracker.current || !(tracker.current instanceof MatomoTracker))) {
        console.info('Analytics: Set up Matomo Analytics');
        tracker.current = new MatomoTracker({
          urlBase: project.configs.matomoBaseUrl,
          siteId: id
        });
      } else if (type === 'gtm' && (!tracker.current || tracker.current instanceof MatomoTracker)) {
        console.info('Analytics: Google Tag Manager');
        tracker.current = GoogleTagManager;
        tracker.current.initialize({
          gtmId: id,
          events: {
            POISelect: 'POI-select'
          }
        });
      } else {
        console.info('Analytics: No analytics tracker configured');
        tracker.current = null;
      }
    }
  }, [project, tracker]);
  var sendPageView = useCallback(function () {
    var _project$configs;
    console.info('Analytics: Sending page view');
    var type = (_project$configs = project.configs) === null || _project$configs === void 0 ? void 0 : _project$configs.analytics;
    var page = window.location.pathname + window.location.search;
    if (type === 'google' && tracker.current) {
      tracker.current.send({
        hitType: "pageview",
        page: window.location.hostname,
        title: "Page Title"
      });
    } else if (type === 'matomo' && tracker.current) {
      tracker.current.trackPageView({
        href: page
      });
    } else if (type === 'gtm' && tracker.current) {
      console.info('Analytics: Tracker does not support page views');
    } else {
      console.info('Analytics: Tracker not set up');
    }
  }, [tracker.current, project]);
  var sendEvent = useCallback(function (_ref) {
    var _project$configs2;
    var category = _ref.category,
      action = _ref.action,
      label = _ref.label;
    console.info('Analytics: Sending custom event');
    var type = (_project$configs2 = project.configs) === null || _project$configs2 === void 0 ? void 0 : _project$configs2.analytics;
    if (type === 'gtm' && tracker.current) {
      tracker.current.dataLayer({
        dataLayer: {
          category: category,
          action: action,
          label: label
        },
        dataLayerName: 'POISelect'
      });
    } else if (type === 'matomo' && tracker.current && tracker.current instanceof MatomoTracker) {
      tracker.current.trackEvent({
        category: category,
        action: action,
        name: label
      });
    } else if (type === 'google' && tracker.current) {
      tracker.current.event({
        category: category,
        action: action,
        label: label
      });
    } else {
      console.info('Analytics: Tracker not set up');
    }
  }, [tracker.current, project]);
  var setDimentions = useCallback(function (dimensions) {
    var _project$configs3;
    console.info('Analytics: Setting new dimensions');
    var type = (_project$configs3 = project.configs) === null || _project$configs3 === void 0 ? void 0 : _project$configs3.analytics;
    if (type !== 'google' && tracker.current) {
      console.warn('Analytics: Tracker does not support dimensions');
    } else if (type === 'google' && tracker.current) {
      tracker.current.set(dimensions);
    } else {
      console.info('Analytics: Tracker not set up');
    }
  }, [tracker.current, project]);
  return {
    sendPageView: sendPageView,
    sendEvent: sendEvent,
    setDimentions: setDimentions
  };
};
export default useAnalytics;